import {TitleService} from 'src/app/services/title.service';
import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ChartConfiguration, ChartData, ChartEvent, ChartType} from 'chart.js';
import {BaseChartDirective} from 'ng2-charts';
import {ServiceStatistics} from '../../services/statistics.service';
import dayjs from 'dayjs';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit, OnChanges {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  @Input() workshop: string;
  @Input() measurement: string;
  @Input() interval: string;
  @Input() startDt: string;
  @Input() endDt: string;
  @Input() series = ['total'];

  @Input() chartType: ChartType = 'bar';

  public chartOptions: ChartConfiguration['options'] = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {
        stacked: true
      },
      y: {
        // min: 10
        stacked: true
      }
    },
    plugins: {
      legend: {
        display: true,
      }
    }
  };

  public chartData: ChartData<ChartType> = {
    labels: [],
    datasets: [
    ]
  };

  constructor(
    private titleService: TitleService,
    private serviceStatistics: ServiceStatistics,
    private translateService: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.titleService.setTitleTranslated('STATISTICS');

    // this.refreshStats();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.workshop || changes.measurement || changes.interval || changes.startDt || changes.endDt || changes.series) {
      this.refreshStats();
    }
  }

  async refreshStats(): Promise<void> {
    let chartTitle = '';
    if (this.measurement === 'bss_count') {
      chartTitle = await this.translateService.get('CTX_STATS.BSS_COUNT').toPromise();
    } else if (this.measurement === 'total_income_with_vat') {
      chartTitle = await this.translateService.get('CTX_STATS.TOTAL_INCOME_WITH_VAT').toPromise();
    //   TODO: Set euro symbol on the labels
    }

    this.serviceStatistics.getStatistics(this.workshop, this.measurement, this.interval, this.startDt, this.endDt).subscribe(async r => {
      const labels = r.stats.map( el => {
        if (this.interval === '1y') {
          return dayjs(el.dt).format('YYYY');
        }
        if (this.interval === '1mo') {
          return dayjs(el.dt).format('MMM-YY');
        }
        if (this.interval === '1w') {
          return dayjs(el.dt).format('DD/MM/YYYY');
        }
        if (this.interval === '1d') {
          return dayjs(el.dt).format('DD/MM/YYYY');
        }

      });

      const datasets = [];
      for (const serie of this.series) {
        // tslint:disable-next-line:no-string-literal
        const dataPoints = r.stats.map(el => el[serie]);
        let backgroundColor = 'rgb(255,117,73)';
        let label = chartTitle;
        if (serie === 'total_tasks') {
          backgroundColor = 'rgb(252,117,73)';
          label = await this.translateService.get('CTX_STATS.TOTAL_INCOME_TASKS_WITH_VAT').toPromise();
        } else if (serie === 'total_products_orders') {
          backgroundColor = 'rgb(61,55,255)';
          label = await this.translateService.get('CTX_STATS.TOTAL_INCOME_PRODUCTS_ORDERS_WITH_VAT').toPromise();
        } else if (serie === 'total_products_bss') {
          backgroundColor = 'rgb(108,104,253)';
          label = await this.translateService.get('CTX_STATS.TOTAL_INCOME_PRODUCTS_BSS_WITH_VAT').toPromise();
        } else if (serie === 'total_rentals') {
          backgroundColor = 'rgb(37,154,1)';
          label = await this.translateService.get('CTX_STATS.TOTAL_INCOME_RENTALS_WITH_VAT').toPromise();
        }
        datasets.push({
          data: dataPoints,
          label,
          backgroundColor
        });
      }

      this.chartData = {
        labels,
        datasets
      };

    });
  }

  // events
  public chartClicked({event, active}: { event?: ChartEvent, active?: {}[] }): void {
    // console.log(event, active);
  }

  public chartHovered({event, active}: { event?: ChartEvent, active?: {}[] }): void {
    // console.log(event, active);
  }

}
