import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {BikesDetailComponent} from './pages/bikes-detail/bikes-detail.component';
import {ClientsDetailComponent} from './pages/clients-detail/clients-detail.component';
import {ClientsListComponent} from './pages/clients-list/clients-list.component';

import {LoginComponent} from './pages/login/login.component';
import {ServicesheetDetailComponent} from './pages/servicesheet-detail/servicesheet-detail.component';
import {ServicesheetListComponent} from './pages/servicesheet-list/servicesheet-list.component';
import {AuthGuard} from './helpers/auth.guard';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {WorkshopListComponent} from './pages/workshop-list/workshop-list.component';
import {NewBssStepperComponent} from './pages/new-bss-stepper/new-bss-stepper.component';
import {PosProductsDetailComponent} from './pages/pos-products-detail/pos-products-detail.component';
import {PosProductsListComponent} from './pages/pos-products-list/pos-products-list.component';
import {PosOrdersDetailComponent} from './pages/pos-orders-detail/pos-orders-detail.component';
import {PosOrdersListComponent} from './pages/pos-orders-list/pos-orders-list.component';
import {StatisticsWorkshopComponent} from './pages/statistics-workshop/statistics-workshop.component';
import {ReportsWorkshopComponent} from './pages/reports-workshop/reports-workshop.component';
import {PosBrandsListComponent} from './pages/pos-brands-list/pos-brands-list.component';
import {PosBrandsDetailComponent} from './pages/pos-brands-detail/pos-brands-detail.component';
import {PosCategoriesListComponent} from './pages/pos-categories-list/pos-categories-list.component';
import {PosCategoriesDetailComponent} from './pages/pos-categories-detail/pos-categories-detail.component';
import {FinancesBankAccountDetailComponent} from './pages/finances-bank-account-detail/finances-bank-account-detail';
import {
  AccountingIncomeInvoicesListComponent
} from './pages/accounting-income-invoices-list/accounting-income-invoices-list.component';
import {CalendarGeneralComponent} from './pages/calendar-general/calendar-general.component';
import {PosSupplierOrdersListComponent} from './pages/pos-supplier-orders-list/pos-supplier-orders-list.component';
import {
  PosSupplierOrdersDetailComponent
} from './pages/pos-supplier-orders-detail/pos-supplier-orders-detail.component';
import {BarCodeScannerComponent} from './pages/barcode-scanner/barcode-scanner';
import {UserChangePassword} from './pages/user_change_password/user_change_password';
import {InventoryCountListComponent} from './pages/inventory-count-list/inventory-count-list.component';
import {InventoryCountDetailComponent} from './pages/inventory-count-detail/inventory-count-detail.component';
import {RentalsListComponent} from './pages/rentals-list/rentals-list.component';
import {RentalDetailComponent} from './pages/rentals-detail/rental-detail.component';
import {BikesListV2Component} from './pages/bikes-list-v2/bikes-list-v2.component';
import {RentalsTariffsListComponent} from './pages/rentals-tariffs-list/rentals-tariffs-list.component';
import {RentalsTariffsDetailComponent} from './pages/rentals-tariffs-detail/rentals-tariffs-detail.component';
import {RentalsAccessoriesListComponent} from './pages/rentals-accessories-list/rentals-accessories-list.component';
import {RentalsAccessoryDetailComponent} from './pages/rentals-accessory-detail/rentals-accessory-detail.component';
import {ReminderListComponent} from './components/reminder-list/reminder-list.component';

const routes: Routes = [
  // TODO: remove
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'workshops', component: WorkshopListComponent, canActivate: [AuthGuard] },
  { path: 'new_bss_stepper', component: NewBssStepperComponent, canActivate: [AuthGuard] },

  { path: 'barcode-scanner', component: BarCodeScannerComponent, canActivate: [AuthGuard] },

  { path: 'staff', component: ClientsListComponent, canActivate: [AuthGuard] },
  { path: 'staff/create', component: ClientsDetailComponent, canActivate: [AuthGuard] },
  { path: 'staff/:id', component: ClientsDetailComponent, canActivate: [AuthGuard] },

  { path: 'clients', component: ClientsListComponent, canActivate: [AuthGuard] },
  { path: 'clients/create', component: ClientsDetailComponent, canActivate: [AuthGuard] },
  { path: 'clients/:id', component: ClientsDetailComponent, canActivate: [AuthGuard] },

  { path: 'suppliers', component: ClientsListComponent, canActivate: [AuthGuard] },
  { path: 'suppliers/create', component: ClientsDetailComponent, canActivate: [AuthGuard] },
  { path: 'suppliers/:id', component: ClientsDetailComponent, canActivate: [AuthGuard] },

  { path: 'pos/products', component: PosProductsListComponent, canActivate: [AuthGuard] },
  { path: 'pos/products/create', component: PosProductsDetailComponent, canActivate: [AuthGuard] },
  { path: 'pos/products/:id', component: PosProductsDetailComponent, canActivate: [AuthGuard] },

  { path: 'pos/brands', component: PosBrandsListComponent, canActivate: [AuthGuard] },
  { path: 'pos/brands/create', component: PosBrandsDetailComponent, canActivate: [AuthGuard] },
  { path: 'pos/brands/:id', component: PosBrandsDetailComponent, canActivate: [AuthGuard] },

  { path: 'pos/categories', component: PosCategoriesListComponent, canActivate: [AuthGuard] },
  { path: 'pos/categories/create', component: PosCategoriesDetailComponent, canActivate: [AuthGuard] },
  { path: 'pos/categories/:id', component: PosCategoriesDetailComponent, canActivate: [AuthGuard] },

  { path: 'pos/orders', component: PosOrdersListComponent, canActivate: [AuthGuard] },
  { path: 'pos/orders/create', component: PosOrdersDetailComponent, canActivate: [AuthGuard] },
  { path: 'pos/orders/:id', component: PosOrdersDetailComponent, canActivate: [AuthGuard] },
  { path: 'pos/supplier_orders', component: PosSupplierOrdersListComponent, canActivate: [AuthGuard] },
  { path: 'pos/supplier_orders/create', component: PosSupplierOrdersDetailComponent, canActivate: [AuthGuard] },
  { path: 'pos/supplier_orders/:id', component: PosSupplierOrdersDetailComponent, canActivate: [AuthGuard] },

  { path: 'rentals', component: RentalsListComponent, canActivate: [AuthGuard] },
  { path: 'rentals/create', component: RentalDetailComponent, canActivate: [AuthGuard] },
  { path: 'rentals/:id', component: RentalDetailComponent, canActivate: [AuthGuard] },
  { path: 'rentals_accessories', component: RentalsAccessoriesListComponent, canActivate: [AuthGuard] },
  { path: 'rentals_accessories/create', component: RentalsAccessoryDetailComponent, canActivate: [AuthGuard] },
  { path: 'rentals_accessories/:id', component: RentalsAccessoryDetailComponent, canActivate: [AuthGuard] },
  { path: 'bikes_rental', component: BikesListV2Component, canActivate: [AuthGuard] },
  { path: 'bikes_rental/create', component: BikesDetailComponent, canActivate: [AuthGuard] },
  { path: 'bikes_rental/:id', component: BikesDetailComponent, canActivate: [AuthGuard] },
  { path: 'bikes_rental_tariffs', component: RentalsTariffsListComponent, canActivate: [AuthGuard] },
  { path: 'bikes_rental_tariffs/create', component: RentalsTariffsDetailComponent, canActivate: [AuthGuard] },
  { path: 'bikes_rental_tariffs/:id', component: RentalsTariffsDetailComponent, canActivate: [AuthGuard] },

  { path: 'bikes', component: BikesListV2Component, canActivate: [AuthGuard] },
  { path: 'bikes/create', component: BikesDetailComponent, canActivate: [AuthGuard] },
  { path: 'bikes/:id', component: BikesDetailComponent, canActivate: [AuthGuard] },

  { path: 'budgets', component: ServicesheetListComponent, canActivate: [AuthGuard] },
  { path: 'servicesheets', component: ServicesheetListComponent, canActivate: [AuthGuard] },
  { path: 'servicesheets/create', component: ServicesheetDetailComponent, canActivate: [AuthGuard] },
  { path: 'servicesheets/:id', component: ServicesheetDetailComponent, canActivate: [AuthGuard] },

  { path: 'finances/accounts/:id', component: FinancesBankAccountDetailComponent, canActivate: [AuthGuard] },
  { path: 'accounting/income/invoices', component: AccountingIncomeInvoicesListComponent, canActivate: [AuthGuard] },

  { path: 'statistics_workshop', component: StatisticsWorkshopComponent, canActivate: [AuthGuard] },
  { path: 'reports_workshop', component: ReportsWorkshopComponent, canActivate: [AuthGuard] },

  { path: 'inventory_counts', component: InventoryCountListComponent, canActivate: [AuthGuard] },
  { path: 'inventory_counts/create', component: InventoryCountDetailComponent, canActivate: [AuthGuard] },
  { path: 'inventory_counts/:id', component: InventoryCountDetailComponent, canActivate: [AuthGuard] },

  { path: 'calendar_general', component: CalendarGeneralComponent, canActivate: [AuthGuard] },
  { path: 'reminders', component: ReminderListComponent, canActivate: [AuthGuard] },

  { path: 'user_change_passoword', component: UserChangePassword, canActivate: [AuthGuard] },

  { path: 'login', component: LoginComponent },
  { path: 'login/:businessSlug', component: LoginComponent },

  // otherwise, redirect to home
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: '**', component: NotFoundComponent, canActivate: [AuthGuard] },
  // { path: '**', redirectTo: '', canActivate: [AuthGuard] }
  // { path: '**', redirectTo: 'dashboard', canActivate: [AuthGuard] }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
