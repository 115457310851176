import {
  AfterContentChecked,
  Component,
  ComponentRef,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Observable, Subject} from 'rxjs';
import {first, takeUntil} from 'rxjs/operators';
import {BikeCRMApiError} from 'src/app/models/api';
import {CrmNotificationsService} from 'src/app/services/crm-notifications.service';
import {AbstractBaseItem} from '../../models/abstract_base_api_item';
import {BikeCRMApiAbstract} from '../../services/bikecrm-api-base';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogModel
} from '../utils/confirmation-dialog/confirmation-dialog.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {NativeInterfacesService} from '../../services/native-interfaces.service';
import {BarcodeScannerAbstractComponent} from '../abstract-barcode-scanner/abstract-barcode-scanner';
import {UsersService} from '../../services/users.service';


export enum DetailFieldTypes {
  text,
  currency,
  number,
  picture,
  profile_picture,
  date,
  code,
  percent,
  boolean,
  calculated,
  hidden,
  textArea,
  phone,
  selector
}

export class UIMetadata {
  constructor(align: string, row: number, wPercent: number) {
    this.align = align;
    this.row = row;
    this.widthPercent = wPercent;
  }

  align = 'start';
  row = 0;
  widthPercent = 0;
}

export class DetailField {
  constructor(i18nKey: string,
              type: DetailFieldTypes,
              defaultValue: any,
              uiMetadata: any[],
              v?: [((control: AbstractControl) => (ValidationErrors | null))],
              readOnly?: boolean) {
    this.i18nKey = i18nKey;
    this.type = type;
    this.uiMetadata = new UIMetadata(uiMetadata[0], uiMetadata[1], uiMetadata[2]);
    this.defaultValue = defaultValue;
    this.validators = v ? v : null;
    this.readOnly = readOnly ? readOnly : false;
  }

  i18nKey: string;
  uiMetadata: UIMetadata;
  type: DetailFieldTypes;
  defaultValue: any;
  readOnly: boolean;
  validators: [((control: AbstractControl) => (ValidationErrors | null))];
}

export interface IItemDetail {
  navBase: string;
  editableFields: { [key: string]: DetailField };

  // TODO: those can be extracted to another interface implemented in more abstract classes
  loading: boolean;
  errorLoading: boolean;

  rowList(): number[];

  getInputTypeCalculated(field: string): string;

  getCalculatedField(field: string): any;

  setCalculatedField(modifiedText: string, field: string): void;
}

@Component({
  template: '',
})
export abstract class ItemDetailAbstractComponent<T extends AbstractBaseItem> extends BarcodeScannerAbstractComponent
  implements OnInit, OnDestroy, AfterContentChecked, IItemDetail {
  // TODO: enable fully barcode scanner if fully is present
  // TODO: check how we can read barcodes on web without fully

  // TODO: check that all those fields are available as properties in T
  abstract navBase: string;
  abstract editableFields: { [key: string]: DetailField };

  // TODO: change this printLabelsEnabled and movestock enabled to some sort of action buttons list
  printLabelsEnabled = false;
  moveStockEnabled = false;

  selectorFieldsOptions: { [key: string]: Array<{ value: string, label: string }> } = {};

  loading = false;
  errorLoading = false;

  initialCreateFormData = new FormData();

  itemId: string;
  extraMessageI18nKey: string;
  editMode = false;
  dialogMode = false;
  submitInProgress = false;

  // detail fields will be hidden, unless you are on edit mode
  hideDetailFields = false;

  // https://stackoverflow.com/questions/44939878/dynamically-adding-and-removing-components-in-angular
  showRelatedComponent = true;
  @ViewChild('relatedComponentHost', {read: ViewContainerRef}) relatedComponentHost: ViewContainerRef;
  relatedComponentRef: ComponentRef<any>;

  showTopComponent = false;
  @ViewChild('topComponentHost', {read: ViewContainerRef}) topComponentHost: ViewContainerRef;
  topComponentRef: ComponentRef<any>;
  // tslint:disable-next-line:variable-name
  _relatedHostLoaded = false;

  item$: Observable<T>;
  item: T;

  itemForm: UntypedFormGroup;

  itemNotFound = false;

  inFully = false;

  currencyCode: string;

  protected onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    @Optional() public dialogRef: MatDialogRef<any>, // TODO: improve typehint
    @Optional() @Inject(MAT_DIALOG_DATA) protected data: { itemId: string, extraMessageI18nKey: string },
    protected router: Router,
    protected route: ActivatedRoute,
    protected notificationService: CrmNotificationsService,
    protected translate: TranslateService,
    protected formBuilder: UntypedFormBuilder,
    protected dialog: MatDialog,
    protected itemsService: BikeCRMApiAbstract,
    protected nativeInterfacesService: NativeInterfacesService,
    protected usersService: UsersService,
  ) {
    super(nativeInterfacesService, dialog);
    this.inFully = nativeInterfacesService.isFully();
  }

  ngOnInit(): void {
    this.currencyCode = this.usersService.business.currency.toUpperCase();

    if (this.dialogRef != null) {
      this.dialogMode = true;
      this.itemId = this.data.itemId;
      this.extraMessageI18nKey = this.data.extraMessageI18nKey;
      this.editMode = true;
      this.showRelatedComponent = !this.editMode;
    } else {
      this.itemId = this.route.snapshot.paramMap.get('id');
    }
    if (this.itemId == null) {
      // New Item
      this.editMode = true;
      this.showRelatedComponent = !this.editMode;
      const controlsConfig = {};
      for (const field of Object.keys(this.editableFields)) {
        const l: Array<any> = [this.editableFields[field].defaultValue];
        if (this.editableFields[field].validators) {
          l.push(this.editableFields[field].validators);
        }
        controlsConfig[field] = l;
      }
      this.itemForm = this.formBuilder.group(controlsConfig);
      this.onFormReady();
    } else {
      // Editing / viewing existing item
      this.loadData();
    }

    this.route.queryParams.subscribe((params: Params) => {
      // tslint:disable-next-line:no-string-literal
      const editParam = params['edit'] === 'edit' ? true : this.editMode;
      this.setEditMode(editParam);
    });

    this.configureKeyboardShortcuts();
  }

  ngAfterContentChecked(): void {
    if (!this._relatedHostLoaded && this.relatedComponentHost != null && this.item != null) {
      this.loadRelatedComponent();
      this.loadTopComponent();
      this._relatedHostLoaded = true;
    }
  }

  setEditMode(enabled: boolean): void {
    // console.log('setEditMode', enabled);
    // TODO check for changes and ask for confirmation to leave edit mode with unsaved changes
    this.editMode = enabled;

    if (!this.dialogMode) {
      // Don't change the URL if we are in dialog mode
      this.router.navigate([], {
        queryParams: {edit: this.editMode ? 'edit' : null},
        queryParamsHandling: 'merge'
      });
    }
    this.showRelatedComponent = !this.editMode;
    if (this.showRelatedComponent) {
      this.loadRelatedComponent();
    }
    this.configureKeyboardShortcuts();
  }

  editableFieldsList(row: number = null): Array<string> {
    if (row === null) {
      return Object.keys(this.editableFields);
    }
    return Object.keys(this.editableFields).filter(k => this.editableFields[k].uiMetadata.row === row);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  onFormReady(): void {
    // this.route.params.subscribe((params: Params) => {
    //   // tslint:disable-next-line:no-string-literal
    //   const editParam = params['edit'] === 'edit' ? true : this.editMode;
    //   this.setEditMode(editParam);
    // });
    // console.log('onFormReady');
    // this.route.queryParams.subscribe((params: Params) => {
    //   // tslint:disable-next-line:no-string-literal
    //   const editParam = params['edit'] === 'edit' ? true : this.editMode;
    //   this.setEditMode(editParam);
    // });
  }

  loadTopComponent(): void {
  }

  loadRelatedComponent(): void {
  }

  loadDataItem(item: T = null): void {
    this.item = item;
    this.loading = false;
    this.errorLoading = false;

    const controlsConfig = {};
    for (const field of Object.keys(this.editableFields)) {
      const l: Array<any> = [item[field]];
      if (this.editableFields[field].type === DetailFieldTypes.calculated) {
        l[0] = this.getCalculatedField(field);
      }
      if (this.editableFields[field].validators) {
        l.push(this.editableFields[field].validators);
      }
      if (l[0] == null) {
        l[0] = this.editableFields[field].defaultValue;
      }
      controlsConfig[field] = l;
    }
    this.itemForm = this.formBuilder.group(controlsConfig);
    this.onFormReady();
    // TODO: replace this with signals or subjects, then we don't need to reassing and create a new observable
    this.item$ = new Observable<T>(observer => {
      observer.next(item);
      observer.complete();
    });
  }

  loadData(): void {
    if (this.itemId == null) {
      // TODO: report to sentry as handled
      console.log('loadData() called with itemId null, this should not happen');
      return;
    }

    this.loading = true;
    this.errorLoading = false;

    this.item$ = this.itemsService.get(this.itemId);
    this.item$.subscribe(item => {
      this.loadDataItem(item);
    }, error => {
      this.loading = false;
      this.errorLoading = true;
    });
  }

  retryLoadData(): void {
    this.loadData();
  }

  isFieldVisible(field: string): boolean {
    return true;
  }

  isFieldEditable(field: string): boolean {
    return !this.editableFields[field].readOnly;
  }

  public rowList(): number[] {
    const l = new Set<number>();
    for (const f of Object.keys(this.editableFields)) {
      // if (this.editableFields[f].readOnly) {
      //   continue;
      // }
      l.add(this.editableFields[f].uiMetadata.row);
    }
    return [...l.values()];
  }

  public get fieldTypes(): typeof DetailFieldTypes {
    return DetailFieldTypes;
  }

  async showError(resp: BikeCRMApiError): Promise<void> {
    // TODO: do message translations and details on server
    // TODO: if you don't pass a mandatory field, this cases crashes and we don't show any feedback
    // TODO: test:
    if (resp.error.hasOwnProperty('error_i18n')) {
      // tslint:disable-next-line:no-string-literal
      this.notificationService.warningI18N(resp.error['error_i18n']);
    } else {
      let c = 0;
      for (const errField of Object.keys(resp.error)) {
        c = c + 1;
        const baseMsg = await this.translate.get('FEEDBACK_MESSAGES.INVALID').toPromise();
        const msg = `${baseMsg} ${errField}: ${resp.error[errField][0]}`;
        this.notificationService.warning(msg);
        if (c > 4) {
          // TODO: report on sentry
          break;
        }
      }
    }
  }

  getSelectFieldOptionsList(field: string): Array<{ value: string, label: string }> {
    throw Error('if the class has any related fields you should override getRelatedFieldOptionsList');
  }

  getInputTypeCalculated(field: string): string {
    throw Error('if the class has any calculated fields you should override getInputTypeCalculated, getCalculatedField and setCalculatedField');
  }

  getCalculatedField(field: string): any {
    throw Error('if the class has any calculated fields you should override getCalculatedField, getCalculatedField and setCalculatedField');
  }

  setCalculatedField(modifiedText: string, field: string): void {
    throw Error('if the class has any calculated fields you should override setCalculatedField, getCalculatedField and setCalculatedField');
  }

  printLabelItem(item: T, format: string): void {
    throw Error('if print is enabled, you should override printLabelItem()');
  }

  moveStockItem(item: T): void {
    throw Error('if move stock is enabled, you should override moveStockItem()');
  }

  async confirmDelete(item: T): Promise<void> {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: new ConfirmationDialogModel(`Confirm deletion of: ${AbstractBaseItem.str(item)}?`, null, 'delete', 'delete', 'warn')
      })
      .afterClosed()
      .subscribe(async (confirmDialog: boolean) => {
        if (confirmDialog) {
          await this.itemsService.delete(item.id).toPromise();
          this.router.navigate([this.navBase]);
        } else {
          // cancelled
        }
      });
  }

  hasProfilePictures(): boolean {
    // TODO: cache result? this is called on the templates, and may cause performance issues
    for (const k of Object.keys(this.editableFields)) {
      if (this.editableFields[k].type === DetailFieldTypes.profile_picture) {
        return true;
      }
    }
    return false;
  }

  cleanInputToString(input: any, currency = false): string {
    //  Move to a service?

    input = input.toString();
    input = input.trim();
    if (currency) {
      input = input.replaceAll(',', '.');
      input = input.replaceAll(`'`, '.');
    }
    return input;
  }

  getLiteral(item: T): string {
    if ('name' in item) {
      // tslint:disable-next-line:no-string-literal
      return item['name'];
    }
    // throw Error('literal not found for this Item type');
    return '';
  }

  getProfilePictures(item: T): string {
    // TODO: search by fieldType and not searching for the correct key
    if ('profilePicture' in item) {
      // tslint:disable-next-line:no-string-literal
      return item['profilePicture'];
    }
    // throw Error('profile_picture not found for this Item type');
    return '';
  }

  getMainPicture(item: T): string {
    return AbstractBaseItem.pic(item);
  }

  onFileChange(event, field): void {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      // console.log(file);
      this.itemForm.get(field).setValue(file);
    }
  }

  getContactPhoneItem(item): string {
    console.log('you should override getContactPhoneItem');
    return '';
  }

  whatsAppProbablyInstalled(): boolean {
    // TODO: move to a service? and dedup with abstract item detail and service sheet detail
    // TODO: improve somehow
    return /iPhone|Android/i.test(navigator.userAgent);
  }

  openCall(item): void {
    // TODO: move to a service? and dedup with abstract item detail and service sheet detail
    window.open(`tel:${this.getContactPhoneItem(item)}`, '_blank');
  }

  openWhatsApp(item): void {
    // TODO: move to a service? and dedup with abstract item detail and service sheet detail
    let p = this.getContactPhoneItem(item);

    // https://faq.whatsapp.com/general/chats/how-to-use-click-to-chat/?lang=en
    // https://stackoverflow.com/questions/21935149/sharing-link-on-whatsapp-from-mobile-website-not-application-for-android
    p = p.split('-').join('');
    p = p.split('+').join('');
    p = p.split('(').join('');
    p = p.split(')').join('');
    p = p.replace(/^0+/, '');

    // TODO: improve this logic, what happens with other countries? and if the phone starts with 34 but it still has no country code on it
    // TODO: this should come from the server, with a default prefix for the workshop in all the phones
    // TODO: remove
    // if (p.slice(0, 2) !== '34') {
    //   p = `34${p}`;
    // }

    // const url = `https://wa.me/${p}`;
    const url = `https://api.whatsapp.com/send?text=&phone=${p}`;
    // if (this.whatsAppProbablyInstalled()) {
    //   url = `https://web.whatsapp.com/send?text=&phone=${p}`;
    // }
    // window.open(url, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    // console.log(url);
    window.open(url, '_blank');
  }

  saveAndAddOther(): void {
    this.onFormSubmit(true);
  }

  onFormSubmit(addOtherOnFinish = false): void {
    this.submitInProgress = true;

    const formData = this.initialCreateFormData;

    for (const field of this.editableFieldsList()) {
      if (!this.isFieldEditable(field)) {
        continue;
      }
      if (this.editableFields[field].type === DetailFieldTypes.picture
        || this.editableFields[field].type === DetailFieldTypes.profile_picture) {
        // If it's string we haven't updated the image
        if (this.itemForm.get(field).value != null && typeof this.itemForm.get(field).value !== 'string') {
          formData.append(field, this.itemForm.get(field).value);
        }
        continue;
      }

      let val = this.itemForm.get(field).value;
      if (this.editableFields[field].type === DetailFieldTypes.calculated) {
        val = this.setCalculatedField(val, field);
      }

      // Convert number fields from using commas to dots:
      // TODO: move to a service?
      if (this.editableFields[field].type === DetailFieldTypes.currency || this.editableFields[field].type === DetailFieldTypes.number
        || (this.editableFields[field].type === DetailFieldTypes.calculated && this.getInputTypeCalculated(field) === 'number')) {
        val = Number(String(val).replaceAll(',', '.'));
      }

      if (this.editableFields[field].type === DetailFieldTypes.percent) {
        val = Number(val);
        if (this.itemForm.get(field).value > 1 && this.itemForm.get(field).value < 100) {
          val = val / 100;
        }
        // TODO: what happens if is negative? of higher than 100?
      }

      if (this.editableFields[field].validators == null || !this.editableFields[field].validators.includes(Validators.required)) {
        // optional fields
        formData.append(field, val);
      } else {
        // required fields
        if (this.itemForm.get(field).value == null || String(this.itemForm.get(field).value).replaceAll(' ', '').length === 0) {
          continue;
        }
        // https://stackoverflow.com/questions/10032024/how-to-remove-leading-and-trailing-white-spaces-from-a-given-html-string
        formData.append(field, String(val).trim());
      }
    }

    if (this.itemId == null) {
      // New Item
      this.itemsService.create(formData)
        .pipe(
          first(),
          takeUntil(this.onDestroy$)
        )
        .subscribe({
          next: (createdItem: T) => {
            if (this.dialogRef) {
              this.dialogRef.close(createdItem);
            } else {
              this.setEditMode(false);
              this.submitInProgress = false;
              this.loadDataItem(createdItem);
              console.log('addOtherOnFinish', addOtherOnFinish);
              if (addOtherOnFinish) {
                this.notificationService.successI18N('FEEDBACK_MESSAGES.ITEM_SAVED', formData.get('name').toString());
                this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                  this.router.navigate([`${this.navBase}create`]);
                });
              } else {
                this.router.navigate([`${this.navBase}${createdItem.id}`], {replaceUrl: true});
              }
            }
          },
          error: async (resp: BikeCRMApiError) => {
            this.showError(resp);
            this.submitInProgress = false;
          }
        });
    } else {
      // Update existing item
      this.itemsService.modify(this.itemId, formData)
        .pipe(
          first(),
          takeUntil(this.onDestroy$)
        )
        .subscribe({
          next: (modifiedItem: T) => {
            if (this.dialogRef) {
              this.dialogRef.close(modifiedItem);
            } else {
              this.setEditMode(false);
              this.submitInProgress = false;
              this.loadDataItem(modifiedItem);
            }
            if (addOtherOnFinish) {
              this.notificationService.successI18N('FEEDBACK_MESSAGES.ITEM_SAVED', formData.get('name').toString());
              this.router.navigate([`${this.navBase}create`], {replaceUrl: true});
            }
          },
          error: async (resp: BikeCRMApiError) => {
            this.showError(resp);
            this.submitInProgress = false;
          }
        });
    }
  }

  configureKeyboardShortcuts(): void {
  }

}
