<div fxFlex fxLayout="column" class="base-paddings" fxLayoutGap="20px">
  <div fxFlex="100" fxLayout="row" fxLayoutAlign="center">
    <mat-icon>info</mat-icon>
    <span>{{ "CTX_STATS.STATISTICS_ARE_FILTERED_BY_CREATION_NOT_PAYMENT" | translate }}</span>
  </div>

  <div id="global_filters" fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="center" fxLayoutGap="10px">

    <mat-form-field fxFlex="95" fxFlex.gt-md="20" *ngIf="workshopsList.length > 1" appearance="outline">
      <mat-label>Workshop</mat-label>
      <mat-select placeholder="Workshop" [(ngModel)]="workshop">
        <mat-option value="all">All</mat-option>
        <mat-option *ngFor="let workshop of workshopsList" [value]="workshop.id">{{ workshop.name }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex="95" fxFlex.gt-md="10" appearance="outline">
      <mat-label>Aggregation</mat-label>
      <mat-select placeholder="Interval" [(ngModel)]="interval">
<!--        <mat-option value="1d">Day</mat-option>-->
        <mat-option value="1w">Weekly</mat-option>
        <mat-option value="1mo">Monthly</mat-option>
<!--        <mat-option value="1y">Year</mat-option>-->
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex="95" fxFlex.gt-md="15" appearance="outline">
      <mat-label>Period</mat-label>
      <mat-select placeholder="Workshop" [(ngModel)]="period" (selectionChange)="onPeriodChange()">
        <mat-option value="last12months">Last 12 months</mat-option>
        <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
      </mat-select>
    </mat-form-field>

  </div>

  <div fxFlex fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="center">

    <app-statistics fxFlex="95" fxFlex.gt-md="45" measurement="{{ incomeMeasurement }}" [series]="incomeSeries"
                    workshop="{{ workshop }}" interval="{{ interval }}" startDt="{{ startDt }}" endDt="{{ endDt }}"></app-statistics>

    <app-statistics fxFlex="95" fxFlex.gt-md="45" measurement="{{ bssMeasurement }}" interval="{{ interval }}"
                    workshop="{{ workshop }}" startDt="{{ startDt }}" endDt="{{ endDt }}"></app-statistics>

  </div>
</div>
