import {TitleService} from 'src/app/services/title.service';
import {Component, OnInit} from '@angular/core';
import dayjs from 'dayjs';
import {UsersService} from '../../services/users.service';

@Component({
  selector: 'app-statistics-workshop',
  templateUrl: './statistics-workshop.component.html',
  styleUrls: ['./statistics-workshop.component.scss']
})
export class StatisticsWorkshopComponent implements OnInit {

  incomeMeasurement = 'total_income_with_vat';
  bssMeasurement = 'bss_count';

  incomeSeries = ['total_tasks', 'total_products_orders', 'total_products_bss'];

  workshopsList = [];

  // global filters
  workshop = '';
  interval = '1mo';
  years = [];
  period = 'last12months';
  startDt = '';
  endDt = '';

  constructor(
    private titleService: TitleService,
    private usersService: UsersService
  ) {
  }

  ngOnInit(): void {
    if (this.usersService.userMe && this.usersService.userMe.workshop) {
      // TODO: this should not happen, we should wait for the user to be loaded (maybe on a loading screen?)
      this.workshop = this.usersService.userMe.workshop;
      this.workshopsList = this.usersService.business?.workshops || [];
      const createdYear = this.usersService.business?.createdAt ? dayjs(this.usersService.business.createdAt).year() : 2020;
      this.years = Array.from({length: new Date().getFullYear() - createdYear + 1}, (v, k) => k + createdYear);
      this.years.reverse();
      if (this.usersService.business?.rentalEnabled) {
        this.incomeSeries.push('total_rentals');
      }
    }
    this.titleService.setTitleTranslated('CTX_STATS.STATISTICS');
    this.setupChart();
  }

  onPeriodChange(): void {
    if (this.period === 'last12months') {
      this.startDt = dayjs().subtract(12, 'month').startOf('month').format('DD-MM-YYYY');
      this.endDt = dayjs().endOf('month').format('DD-MM-YYYY');
    } else {
      const selectedYear = Number(this.period);
      this.startDt = dayjs().year(selectedYear).startOf('year').format('DD-MM-YYYY');
      this.endDt = dayjs().year(selectedYear).endOf('year').format('DD-MM-YYYY');
    }
  }

  setupChart(): void {
  //  https://day.js.org/docs/en/installation/installation
  //  This year
  //   this.startDt = dayjs().startOf('year').format('DD-MM-YYYY');
  //   this.endDt = dayjs().endOf('year').format('DD-MM-YYYY');
  //  Last 12 months:
    this.startDt = dayjs().subtract(12, 'month').startOf('month').format('DD-MM-YYYY');
    this.endDt = dayjs().endOf('month').format('DD-MM-YYYY');
  }

}
