import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import dayjs from 'dayjs';
import {Bike} from 'src/app/models/bike';
import {BikesService} from 'src/app/services/bikes.service';
import {OdometerReadingServiceService} from '../../services/odometer-reading.service';

@Component({
  selector: 'app-bike-card',
  templateUrl: './bike-card.component.html',
  styleUrls: ['./bike-card.component.scss']
})
export class BikeCardComponent implements OnInit {
  @Input() bikeId?: string;
  @Input() bike?: Bike;
  @Input() cardstyle = 'normal';  // for now: normal, small, minimal, gridable or button
  @Input() defaultAction = 'go_to_details';  // for now: go_to_details or new_bss
  @Input() detailed = false;

  constructor(
    private router: Router,
    private bikeService: BikesService,
    private odometerReadingService: OdometerReadingServiceService
  ) { }

  ngOnInit(): void {
    if (this.bike == null && this.bikeId != null) {
      this.bikeService.getById(this.bikeId).subscribe(b => {
        this.bike = b; this.bikeId = this.bike.id;
      });
    }
  }

  getBikePictureUrl(bike: Bike): string {
    return Bike.getPictureUrl(bike);
  }

  getCSSBackgroundColor(): string {
    return Bike.getCSSBackgroundColor(this.bike);
  }

  onBikeClick(): void {
    console.log('BikeCardComponent onBikeClick');
    if (this.defaultAction === 'new_bss' || this.defaultAction === 'new_budget') {
      this.router.navigate(['/servicesheets/create', { bikeId: this.bike.id, budget: this.defaultAction === 'new_budget'}], { replaceUrl: true });
      return;
    }
    if (this.defaultAction === 'go_to_details') {
      console.log('BikeCardComponent onBikeClick details');
      this.router.navigate(['/bikes/', this.bike.id ]);
      return;
    }

    // Default
    this.router.navigate(['/bikes/', this.bike.id ]);
  }

  prettyDate(date: string): string {
    return dayjs(date).format('DD/MM/YYYY');
  }

  onOdometerChange(event: string): void {
    if (event == null || event === '' || (this.bike.roOdometer && event === this.bike.roOdometer.toString())) {
      return;
    }

    const newOdometer = parseInt(event, 10);
    const data = {
      bike: this.bike.id,
      odometer: newOdometer,
    };
    this.odometerReadingService.create(data).subscribe(odometerReading => {
      this.bike.roOdometer = odometerReading.odometer;
    });
  }

  getImageSizeWidth(): number {
    if (this.cardstyle === 'normal') {
      return 400;
    }
    if (this.cardstyle === 'small') {
      return 200;
    }
    if (this.cardstyle === 'minimal') {
      return 80;
    }
    if (this.cardstyle === 'gridable') {
      return 250;
    }
    if (this.cardstyle === 'button') {
      return 100;
    }
    return 400;
  }

  getImageSizeHeight(): number {
    if (this.cardstyle === 'normal') {
      return 300;
    }
    if (this.cardstyle === 'small') {
      return 100;
    }
    if (this.cardstyle === 'minimal') {
      return 80;
    }
    if (this.cardstyle === 'gridable') {
      return 150;
    }
    if (this.cardstyle === 'button') {
      return 100;
    }
    return 300;
  }

}
