<!--<mat-toolbar color="accent" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="userService.isLogged" [ngClass]="{'beta_indicator': env.isBeta}">-->
<mat-toolbar color="accent" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="userService.isLogged">
    <div fxLayoutAlign="start center" fxLayoutGap="10px">
        <button mat-icon-button *ngIf="this.media$.isActive('lt-lg')" (click)="opened = !opened">
            <mat-icon>menu</mat-icon>
        </button>
        <a href="/dashboard">
            <img id="logo" src="../assets/brands/{{ brand }}/images/logo_for_dark_bg.png" alt="logo {{ brandName }}"/>
        </a>

        <!-- BackButton only on fully kiosk -->
        <div fxLayoutAlign="center center" *ngIf="nativeInterfacesService.isFully()" class="ml1">
            <mat-icon (click)="goBack()">arrow_back_ios</mat-icon>
        </div>

        <span class="sm-hide xs-hide">{{ title }}</span><small *ngIf="env.isBeta" style="font-size: 8pt; color: gray;"> (β)</small>
    </div>

    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
        <!-- Privacy mode button -->
        <div style="padding-right: 10px;" fxLayoutAlign="center">
          <mat-icon *ngIf="!privacyModeService.isPrivacyModeEnabled()" (click)="privacyModeService.setPrivacyMode(true)">visibility</mat-icon>
          <mat-icon *ngIf="privacyModeService.isPrivacyModeEnabled()" (click)="privacyModeService.setPrivacyMode(false)">visibility_off</mat-icon>
        </div>
        <!-- Battery Status -->
        <div fxLayoutAlign="center center" *ngIf="nativeInterfacesService.isBatteryInfoSupported()">
            <span *ngIf="nativeInterfacesService.batteryLevel | async as level"> {{ level }}% </span>
            <mat-icon *ngIf="nativeInterfacesService.isPlugged | async; else notPlugged">battery_charging_full</mat-icon>
            <ng-template #notPlugged>
                <mat-icon>battery_std</mat-icon>
            </ng-template>
        </div>

        <!-- WIFI status -->
        <ng-container *ngIf="nativeInterfacesService.isWifiInfoSupported()">
            <ng-container *ngIf="nativeInterfacesService.isWifiEnabled | async; else notEnabledWifi">
                    <mat-icon *ngIf="nativeInterfacesService.isWifiConnected | async; else notConnectedWifi">
                        wifi
                    </mat-icon>
            </ng-container>
            <ng-template #notConnectedWifi>
                <mat-icon (click)="nativeInterfacesService.openWifiSettings()" color="warn">signal_wifi_off</mat-icon>
            </ng-template>

            <ng-template #notEnabledWifi>
                <mat-icon (click)="nativeInterfacesService.openWifiSettings()">wifi_off</mat-icon>
            </ng-template>
        </ng-container>

        <!-- Mechanic selector -->
        <span fxLayoutAlign="center center" style="border-color: black;
                     border-radius: 20px;">
            <app-image-profile imgSrc="{{selectedMechanic?.profilePictureUrl }}" name="{{ selectedMechanic?.name }}" w="40" h="40"></app-image-profile>

            <!-- <mat-form-field>
                <mat-select [(value)]="selectedMechanicId" (selectionChange)="selectedMech($event)">
                    <mat-option [value]="mechanic.id" *ngFor="let mechanic of allMechanics">{{ mechanic.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field> -->
        </span>

        <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
        </button>
    </div>

</mat-toolbar>

<mat-menu #menu="matMenu">
    <button mat-menu-item [routerLink]="['/user_change_passoword']">
        <mat-icon>password</mat-icon>
        <span>{{ 'CHANGE_PASSWORD' | translate }}</span>
    </button>
    <button mat-menu-item (click)="refreshPage()">
        <mat-icon>refresh</mat-icon>
        <span>{{ 'REFRESH_PAGE' | translate }}</span>
    </button>
    <button mat-menu-item *ngIf="userService.business && userService.business.stripeEmail" (click)="goToSubscriptionPortal()">
        <mat-icon>business</mat-icon>
        <span>{{ 'SUBSCRIPTION' | translate }}</span>
    </button>
    <button mat-menu-item *ngIf="!fullscreened" (click)="openFullscreen()">
        <mat-icon>fullscreen</mat-icon>
        <span>{{ 'FULLSCREEN' | translate }}</span>
    </button>
    <button mat-menu-item *ngIf="fullscreened" (click)="closeFullscreen()">
        <mat-icon>fullscreen_exit</mat-icon>
        <span>{{ 'CLOSE_FULLSCREEN' | translate }}</span>
    </button>
    <button mat-menu-item (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
        <span>{{ 'LOGOUT' | translate }}</span>
    </button>
</mat-menu>

<mat-sidenav-container class="h100">
    <!-- TODO: explore the use of fixedInViewport -->
    <!-- TODO: https://material.angular.io/components/sidenav/overview#creating-a-responsive-layout-for-mobile--desktop -->
    <!-- IMPORTANT: it's important to have the width in the inline style, to avoid the sidenav to overdraw on top of content on desktop -->
    <!--                I don't know exactly why, it does not work if its on CSS, neither with !important -->
    <mat-sidenav #sidenav [mode]="sideNavMode" [(opened)]="opened" *ngIf="userService.isLogged" style="width: 220px;">

        <div style="background-color: rgb(246 246 246);"
             class="pt2">
            <app-image-kit-image
                class="pb2"
                fxLayoutAlign="center center"
                [imgSrc]="business?.logoUrl"
                w="120"
                h="120"
                classes="circle"
                objectFit="contain">
            </app-image-kit-image>

            <b fxLayout="column"
              fxLayoutAlign="center center"
              class="pb2">
              {{ business?.name }}
              <ng-container *ngIf="business?.workshopCount > 1">
                  <small style="font-size: 8pt; color: gray;"> {{ userMe?.workshopName }}</small>
              </ng-container>
            </b>
        </div>

        <mat-divider></mat-divider>

        <!-- TODO: turn this into a menu component -->
        <ng-container *ngIf="userMe$ | async as userMe">
        <mat-nav-list>
            <mat-list-item [routerLink]="['/dashboard']" [routerLinkActive]="['nav-selected']"
                (click)="onNavItemClicked()">
                <mat-icon matListItemIcon>dashboard</mat-icon>
                <div matListItemTitle>{{ 'DASHBOARD' | translate }}</div>
            </mat-list-item>

            <!-- TODO: translate-->
            <mat-divider></mat-divider>
            <div mat-subheader>General</div>

            <mat-list-item [routerLink]="['/clients']" [routerLinkActive]="['nav-selected']"
                (click)="onNavItemClicked()">
                <mat-icon matListItemIcon>contacts</mat-icon>
                <div matListItemTitle>{{ 'CLIENTS' | translate }}</div>
            </mat-list-item>

            <mat-divider></mat-divider>
            <div mat-subheader>{{ 'STORE' | translate }}</div>

            <mat-list-item [routerLink]="['/pos/products']" [routerLinkActive]="['nav-selected']" (click)="onNavItemClicked()">
                <mat-icon matListItemIcon>local_offer</mat-icon>
                <div matListItemTitle>{{ 'PRODUCTS' | translate }}</div>
            </mat-list-item>

            <mat-list-item [routerLink]="['/pos/orders']" [routerLinkActive]="['nav-selected']" (click)="onNavItemClicked()">
                <mat-icon matListItemIcon>shopping_cart</mat-icon>
                <div matListItemTitle>{{ 'SALES' | translate }}</div>
            </mat-list-item>

            <mat-divider></mat-divider>
            <div mat-subheader>{{ 'WORKSHOP' | translate }}</div>

            <mat-list-item [routerLink]="['/bikes']" [routerLinkActive]="['nav-selected']" (click)="onNavItemClicked()">
                <mat-icon matListItemIcon>pedal_bike</mat-icon>
                <div matListItemTitle>{{ 'BIKES' | translate }}</div>
            </mat-list-item>

            <mat-list-item [routerLink]="['/servicesheets']" [queryParams]="{closed: false}" [routerLinkActive]="['nav-selected']"
                (click)="onNavItemClicked()">
                <mat-icon matListItemIcon>build</mat-icon>
                <div matListItemTitle>{{ 'SERVICE_SHEETS' | translate }}</div>
            </mat-list-item>

            <mat-list-item [routerLink]="['/budgets']" [routerLinkActive]="['nav-selected']"
                (click)="onNavItemClicked()">
                <mat-icon matListItemIcon>receipt</mat-icon>
                <div matListItemTitle>{{ 'BUDGETS' | translate }}</div>
            </mat-list-item>

            <mat-list-item *ngIf="userMe.isBusinessOwner" [routerLink]="['/staff']" [routerLinkActive]="['nav-selected']"
                (click)="onNavItemClicked()">
                <mat-icon matListItemIcon>engineering</mat-icon>
                <div matListItemTitle>{{ 'MECHANICS' | translate }}</div>
            </mat-list-item>

            <mat-list-item [routerLink]="['/workshops']" [routerLinkActive]="['nav-selected']" (click)="onNavItemClicked()">
                <mat-icon matListItemIcon>store</mat-icon>
                <div matListItemTitle>{{ 'WORKSHOP_CONFIG' | translate }}</div>
            </mat-list-item>

<!--          Future financing section -->
          <mat-divider></mat-divider>
<!--          <div mat-subheader>{{ '' | translate }}</div>-->

          <div *ngIf="userMe.hasAccessToBusinessData">
            <mat-list-item [routerLink]="['/finances/accounts/cash']" [routerLinkActive]="['nav-selected']" (click)="onNavItemClicked()">
              <mat-icon matListItemIcon>payments</mat-icon>
              <div matListItemTitle>{{ 'CASH' | translate }}</div>
            </mat-list-item>
          </div>

          <div *ngIf="userMe.hasAccessToBusinessData">
            <mat-list-item [routerLink]="['/accounting/income/invoices']" [routerLinkActive]="['nav-selected']" (click)="onNavItemClicked()">
              <mat-icon matListItemIcon>receipt_long</mat-icon>
              <div matListItemTitle>{{ 'INVOICES' | translate }}</div>
            </mat-list-item>
          </div>

          <mat-divider></mat-divider>
<!--          <div mat-subheader>{{ '' | translate }}</div>-->

          <div>
            <mat-list-item [routerLink]="['/suppliers']" [routerLinkActive]="['nav-selected']" (click)="onNavItemClicked()">
              <mat-icon matListItemIcon>factory</mat-icon>
              <div matListItemTitle>{{ 'SUPPLIERS' | translate }}</div>
            </mat-list-item>
            <mat-list-item [routerLink]="['/pos/brands']" [routerLinkActive]="['nav-selected']" (click)="onNavItemClicked()">
              <mat-icon matListItemIcon>list_alt</mat-icon>
              <div matListItemTitle>{{ 'BRANDS' | translate }}</div>
            </mat-list-item>

            <mat-list-item [routerLink]="['/pos/categories']" [routerLinkActive]="['nav-selected']" (click)="onNavItemClicked()">
              <mat-icon matListItemIcon>category</mat-icon>
              <div matListItemTitle>{{ 'CATEGORIES' | translate }}</div>
            </mat-list-item>
          </div>

          <mat-divider></mat-divider>
          <div *ngIf="userMe.hasAccessToBusinessData" mat-subheader>{{ 'CTX_STATS.STATISTICS' | translate }}{{ 'BETA_STATUS.STATS' | translate }}</div>

          <mat-list-item *ngIf="userMe.hasAccessToBusinessData" [routerLink]="['/statistics_workshop']" [routerLinkActive]="['nav-selected']" (click)="onNavItemClicked()">
            <mat-icon matListItemIcon>insert_chart_outlined</mat-icon>
            <div matListItemTitle>{{ 'CTX_STATS.STATISTICS' | translate }}</div>
          </mat-list-item>


          <mat-list-item *ngIf="userMe.hasAccessToBusinessData" [routerLink]="['/reports_workshop']" [routerLinkActive]="['nav-selected']" (click)="onNavItemClicked()">
            <mat-icon matListItemIcon>table_view</mat-icon>
            <div matListItemTitle>{{ 'CTX_REPORTS.REPORTS' | translate }}</div>
          </mat-list-item>

          <mat-divider></mat-divider>
          <div mat-subheader>BETA</div>
          <mat-list-item [routerLink]="['/calendar_general']" [routerLinkActive]="['nav-selected']" (click)="onNavItemClicked()">
            <mat-icon matListItemIcon>calendar_month</mat-icon>
            <div matListItemTitle>Calendar view</div>
          </mat-list-item>

          <mat-list-item [routerLink]="['/reminders']" [routerLinkActive]="['nav-selected']" (click)="onNavItemClicked()">
            <mat-icon matListItemIcon>reminder</mat-icon>
            <div matListItemTitle>Reminders</div>
          </mat-list-item>

          <ng-container *ngIf="environment.isBeta || business?.enableLabsFeatures">
            <mat-divider></mat-divider>
            <div mat-subheader>Labs</div>

            <mat-list-item [routerLink]="['/bikes_rental']" [routerLinkActive]="['nav-selected']" (click)="onNavItemClicked()">
              <mat-icon matListItemIcon>bike_skooter</mat-icon>
              <div matListItemTitle>Rental Bikes</div>
            </mat-list-item>

            <mat-list-item [routerLink]="['/rentals_accessories']" [routerLinkActive]="['nav-selected']" (click)="onNavItemClicked()">
              <mat-icon matListItemIcon>bike_skooter</mat-icon>
              <div matListItemTitle>Rental accessories</div>
            </mat-list-item>

            <mat-list-item [routerLink]="['/bikes_rental_tariffs']" [routerLinkActive]="['nav-selected']" (click)="onNavItemClicked()">
              <mat-icon matListItemIcon>bike_skooter</mat-icon>
              <div matListItemTitle>Rental Tariffs</div>
            </mat-list-item>

            <mat-list-item [routerLink]="['/rentals']" [routerLinkActive]="['nav-selected']" (click)="onNavItemClicked()">
              <mat-icon matListItemIcon>bike_skooter</mat-icon>
              <div matListItemTitle>Rentals</div>
            </mat-list-item>

            <mat-list-item [routerLink]="['/inventory_counts']" [routerLinkActive]="['nav-selected']" (click)="onNavItemClicked()">
              <mat-icon matListItemIcon>inventory</mat-icon>
              <div matListItemTitle>Inventory counts</div>
            </mat-list-item>

            <mat-list-item [routerLink]="['/pos/supplier_orders']" [routerLinkActive]="['nav-selected']" (click)="onNavItemClicked()">
              <mat-icon matListItemIcon>list_alt</mat-icon>
              <div matListItemTitle>Supplier Orders</div>
            </mat-list-item>

          </ng-container>

            <!-- <div mat-subheader>Others</div>
            <mat-list-item>
                <mat-icon matListItemIcon>receipt</mat-icon>
                Notes
            </mat-list-item> -->
        </mat-nav-list>
        </ng-container>
        <span id="appversionlabel" class="mat-caption pt4">v: {{ appVersion }}</span>
    </mat-sidenav>
    <mat-sidenav-content>
        <div>
            <!-- https://stackoverflow.com/questions/42453375/how-to-call-a-function-on-every-route-change-in-angular2 -->
            <router-outlet (activate)="changeOfRoutes()"></router-outlet>
        </div>

    </mat-sidenav-content>
</mat-sidenav-container>

<hotkeys-cheatsheet></hotkeys-cheatsheet>
