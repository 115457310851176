<div *ngIf="itemNotFound" fxLayout="column" fxLayoutAlign="center" fxLayoutGap="10px" class="p4">
    <mat-icon fxFlexAlign="center">warning</mat-icon>
<!--  TODO: this should not say product, we are on and abstract class -->
    <h2 fxFlexAlign="center" data-cy="product_not_found_message">
        {{ "PRODUCT_NOT_FOUND_MSG" | translate }}
    </h2>
</div>

<div class="base-paddings" style="padding-bottom: 0;" *ngIf="!loading && !errorLoading">

    <div *ngIf="item$ | async as item" fxLayout="row" fxLayoutAlign="end">
        <span *ngIf="!dialogMode && moveStockEnabled" class="ml1">
            <button mat-raised-button (click)="moveStockItem(item)" data-cy="button-move-stock-item">
                <mat-icon>warehouse</mat-icon>
              {{ "MOVE_STOCK" | translate }}
            </button>
        </span>
        <!--       TODO: don't hardcode this options on the abstract template, and make a matmenu to unify the multiple label options-->
        <span *ngIf="!dialogMode && printLabelsEnabled" class="ml1">
            <button mat-raised-button (click)="printLabelItem(item)" data-cy="button-print-label-item">
                <mat-icon>print</mat-icon>
              {{ "LABEL" | translate }}
            </button>
        </span>
      <span *ngIf="!dialogMode && printLabelsEnabled" class="ml1">
            <button mat-raised-button (click)="printLabelItem(item, 'a4')" data-cy="button-print-label-item">
                <mat-icon>print</mat-icon>
              {{ "LABEL_A4" | translate }}
            </button>
        </span>
        <span class="ml1" *ngIf="!dialogMode">
            <button mat-raised-button (click)="setEditMode(!editMode)" data-cy="button-edit-item">
                <mat-icon>create</mat-icon>
                {{ "EDIT" | translate }}
            </button>
        </span>
        <span *ngIf="!dialogMode" class="ml1">
            <button mat-raised-button color="warn" (click)="confirmDelete(item)" data-cy="button-delete-item">
                <mat-icon>delete</mat-icon>
                {{ "DELETE" | translate }}
            </button>
        </span>
    </div>

  <ng-container>
    <div [hidden]="!showTopComponent || editMode">
      <ng-template #topComponentHost></ng-template>
    </div>
  </ng-container>

    <div class="my2" [ngClass]="{'mat-elevation-z2': !dialogMode}">
        <!-- Detail view -->
        <ng-container *ngIf="!editMode && !hideDetailFields">
            <div fxLayout="row" class="p2" *ngIf="item$ | async as item">
                <ng-container *ngIf="getMainPicture(item) && !hasProfilePictures()">
                  <app-image-kit-image [imgSrc]="getMainPicture(item)"
                                     w="200" h="200"></app-image-kit-image>
                </ng-container>

                <ng-container *ngIf="hasProfilePictures()">
                  <app-image-profile [imgSrc]="getProfilePictures(item)" [name]="getLiteral(item)" w="150" h="150"></app-image-profile>
                </ng-container>
                <div fxLayout="column" class="ml2">
                    <span class="pb1" *ngIf="item.prettyId">Id: {{item.prettyId}} </span>
                    <ng-container *ngFor="let field of editableFieldsList()">
                      <ng-container *ngIf="isFieldVisible(field)">
                        <ng-container *ngIf="item[field] || editableFields[field].type === fieldTypes.calculated">
                          <ng-container *ngIf="editableFields[field].type !== fieldTypes.picture && editableFields[field].type !== fieldTypes.profile_picture && editableFields[field].type !== fieldTypes.hidden">
                            <span *ngIf="editableFields[field].type === fieldTypes.text">{{ editableFields[field].i18nKey | translate }}: {{ item[field] }}</span>

                            <div *ngIf="editableFields[field].type === fieldTypes.phone">
                              <span class="mr2">{{ editableFields[field].i18nKey | translate }}: {{ item[field] }}</span>
                              <img *ngIf="!inFully" class="mr2 link-action" aria-hidden="false" aria-label="WhatsApp" (click)="openWhatsApp(item)" src="/assets/images/icons/whatsapp_black_24dp.svg"/>
                              <mat-icon *ngIf="!inFully" class="link-action" aria-hidden="false" aria-label="Call" (click)="openCall(item)">call</mat-icon>
                            </div>

                            <div *ngIf="editableFields[field].type === fieldTypes.textArea" class="mt2">
                              {{ editableFields[field].i18nKey | translate }}:<br/>
                              <div style="white-space:pre-wrap;" class="pl2">{{ item[field] }}</div>

                            </div>
                            <span *ngIf="editableFields[field].type === fieldTypes.currency">{{ editableFields[field].i18nKey | translate }}: {{ item[field] | currency: currencyCode : 'symbol-narrow'}}</span>
                            <span *ngIf="editableFields[field].type === fieldTypes.number">{{ editableFields[field].i18nKey | translate }}: {{ item[field] }}</span>

                            <ng-container *ngIf="editableFields[field].type === fieldTypes.calculated && getInputTypeCalculated(field) === 'currency'">
                              <span>{{ editableFields[field].i18nKey | translate }}: {{ getCalculatedField(field) | currency: currencyCode : 'symbol-narrow' }}</span>
                            </ng-container>
                            <ng-container *ngIf="editableFields[field].type === fieldTypes.calculated && getInputTypeCalculated(field) === 'text'">
                              <span>{{ editableFields[field].i18nKey | translate }}: {{ getCalculatedField(field) | translate }}</span>
                            </ng-container>
                            <ng-container *ngIf="editableFields[field].type === fieldTypes.calculated && getInputTypeCalculated(field) === 'boolean'">
                              <span *ngIf="getCalculatedField(field)">{{ editableFields[field].i18nKey | translate }}: <mat-icon aria-hidden="false" aria-label="{{'YES' | translate}}" style="color: darkgreen">done</mat-icon></span>
                              <span *ngIf="!getCalculatedField(field)">{{ editableFields[field].i18nKey | translate }}: <mat-icon aria-hidden="false" aria-label="{{'NO' | translate}}" style="color: darkred">close</mat-icon></span>
                            </ng-container>

                            <span *ngIf="editableFields[field].type === fieldTypes.date">{{ editableFields[field].i18nKey | translate }}: {{ item[field] | date }}</span>
                            <span *ngIf="editableFields[field].type === fieldTypes.code">{{ editableFields[field].i18nKey | translate }}: {{ item[field] }}</span>
  <!--                          the percent pipe from angular rounds up the number, can cause problems with 7,7% vat for example -->
                            <span *ngIf="editableFields[field].type === fieldTypes.percent">{{ editableFields[field].i18nKey | translate }}: {{ item[field] * 100 }}%</span>

                            <span *ngIf="editableFields[field].type === fieldTypes.boolean && item[field]">{{ editableFields[field].i18nKey | translate }}: <mat-icon aria-hidden="false" aria-label="{{'YES' | translate}}" style="color: darkgreen">done</mat-icon></span>
                            <span *ngIf="editableFields[field].type === fieldTypes.boolean && !item[field]">{{ editableFields[field].i18nKey | translate }}: <mat-icon aria-hidden="false" aria-label="{{'NO' | translate}}" style="color: darkred">close</mat-icon></span>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                </div>

            </div>
        </ng-container>

        <!-- New/edit form -->
        <ng-container *ngIf="editMode">
            <div fxLayout="column" class="p2">
                <h2 *ngIf="!item">{{ "CREATE_NEW" | translate }}</h2>
                <h2 *ngIf="item && !dialogMode">{{ "EDIT" | translate }}</h2>
                <h4>{{ extraMessageI18nKey | translate }}</h4>
                <form [formGroup]="itemForm" (ngSubmit)="onFormSubmit()" fxLayout="column">
                  <ng-container *ngFor="let row_number of rowList()">
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="25px">
                    <ng-container *ngFor="let field of editableFieldsList(row_number)">
                      <ng-container *ngIf="isFieldEditable(field)">

                        <ng-container *ngIf="editableFields[field].type !== fieldTypes.picture && editableFields[field].type !== fieldTypes.profile_picture && editableFields[field].type !== fieldTypes.hidden">
                          <mat-form-field fxFlex.lt-md="100" fxFlex="{{editableFields[field].uiMetadata.widthPercent}}" appearance="fill">
                              <mat-label *ngIf="editableFields[field].type !== fieldTypes.boolean">{{ editableFields[field].i18nKey | translate }}</mat-label>

                            <ng-container *ngIf="editableFields[field].type === fieldTypes.phone">
                              <input matInput formControlName="{{ field }}" name="{{ field }}" autocomplete="off" [attr.data-cy]="'input-' + field">
                            </ng-container>

                            <ng-container *ngIf="editableFields[field].type === fieldTypes.text">
                              <input matInput formControlName="{{ field }}" name="{{ field }}" autocomplete="off" [attr.data-cy]="'input-' + field">
                            </ng-container>

                            <ng-container *ngIf="editableFields[field].type === fieldTypes.number">
                              <input matInput formControlName="{{ field }}" name="{{ field }}" type="number" autocomplete="off" [attr.data-cy]="'input-' + field">
                            </ng-container>

                            <ng-container *ngIf="editableFields[field].type === fieldTypes.date">
                              <input matInput formControlName="{{ field }}" name="{{ field }}" autocomplete="off" [attr.data-cy]="'input-' + field">
                            </ng-container>

                            <ng-container *ngIf="editableFields[field].type === fieldTypes.percent">
                              <input matInput formControlName="{{ field }}" name="{{ field }}" autocomplete="off" [attr.data-cy]="'input-' + field">
                            </ng-container>

                              <ng-container *ngIf="editableFields[field].type === fieldTypes.currency">
                                <input matInput formControlName="{{ field }}" type="number" min="0" step="any" name="{{ field }}" autocomplete="off" [attr.data-cy]="'input-' + field">
                              </ng-container>

                            <ng-container *ngIf="editableFields[field].type === fieldTypes.textArea">
                              <textarea matInput rows="4" formControlName="{{ field }}" name="{{ field }}" autocomplete="off" [attr.data-cy]="'input-' + field"></textarea>
                            </ng-container>

                              <ng-container *ngIf="editableFields[field].type === fieldTypes.calculated && getInputTypeCalculated(field) != 'boolean'">
                                <input matInput formControlName="{{ field }}" type="{{ getInputTypeCalculated(field) }}" min="0" step="any" name="{{ field }}" autocomplete="off" [attr.data-cy]="'input-' + field">
                              </ng-container>
                              <ng-container *ngIf="editableFields[field].type === fieldTypes.calculated && getInputTypeCalculated(field) == 'boolean'">
                                <input hidden matInput>
                                <mat-checkbox formControlName="{{ field }}" name="{{ field }}">{{ editableFields[field].i18nKey | translate }}</mat-checkbox>
                              </ng-container>


                              <ng-container *ngIf="editableFields[field].type === fieldTypes.selector">
                                <mat-select formControlName="{{ field }}">
<!--                                  <option value=""></option>-->
                                  <mat-option *ngFor="let option of getSelectFieldOptionsList(field)" [value]="option.value">
                                    {{ option.label | translate }}
                                  </mat-option>
                                </mat-select>
                              </ng-container>

                              <ng-container *ngIf="editableFields[field].type === fieldTypes.code">
                                <!-- If it's code, we ignore enter, as the client may introduce the code with a barcode scanner that inserts an enter, and will cause onSubmit to trigger -->
                                <input matInput onkeydown="return (event.keyCode!==13);" formControlName="{{ field }}" name="{{ field }}" autocomplete="off" [attr.data-cy]="'input-' + field">
                              </ng-container>

                              <ng-container *ngIf="editableFields[field].type === fieldTypes.boolean">
    <!--                            https://stackoverflow.com/questions/54460878/mat-form-field-must-contain-a-matformfieldcontrol-and-already-imported-->
                                  <input hidden matInput>
                                  <mat-checkbox formControlName="{{ field }}" name="{{ field }}">{{ editableFields[field].i18nKey  | translate }}</mat-checkbox>
                              </ng-container>
    <!--                             <mat-hint>Hint</mat-hint>-->

                          </mat-form-field>
                        </ng-container>
                        <ng-container *ngIf="editableFields[field].type === fieldTypes.picture || editableFields[field].type === fieldTypes.profile_picture">
    <!--                      TODO: use some nice library-->
    <!--                      TODO: https://github.com/nishantmc/angular-material-fileupload -->
    <!--                      TODO: https://github.com/h2qutc/angular-material-components -->
                          <app-image-kit-image *ngIf="item$ | async as item" [imgSrc]="item[field]"
                                               w="200" h="200"></app-image-kit-image>

                          <input type="file" accept=".jpg,.png,.jpeg" name="{{ field }}"
                                 placeholder="{{ 'UPLOAD_A_PROFILE_PICTURE' | translate}}"
                                 [attr.data-cy]="'input-' + field"
                                 (change)="onFileChange($event, field)">
                        </ng-container>
                      </ng-container>
                    </ng-container>
                    </div>
                  </ng-container>

                    <div fxLayout="row-reverse" fxLayoutGap="20px">
                      <button mat-raised-button color="primary" type="submit" class="mt3i" *ngIf="!submitInProgress"
                              [disabled]="!itemForm.valid" data-cy="button-submit-item-info">
                          {{ "SAVE" | translate }}
                      </button>

                      <button mat-raised-button (click)="saveAndAddOther()" class="mt3i" *ngIf="!submitInProgress"
                              [disabled]="!itemForm.valid">
                        {{ "SAVE_AND_ADD_OTHER" | translate }}
                      </button>

                      <button mat-raised-button color="primary" disabled class="mt3i" *ngIf="submitInProgress" class="pt-2">
                          <span fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                              <div>{{ "SAVING" | translate }}...</div>
                              <div>
                                  <mat-spinner diameter="20"></mat-spinner>
                              </div>
                          </span>
                      </button>
                    </div>

                </form>
            </div>
        </ng-container>
    </div>

    <ng-container>
      <div [hidden]="!showRelatedComponent">
          <ng-template #relatedComponentHost></ng-template>
      </div>
    </ng-container>

</div>

<!-- TODO: implement as a component?-->
<div *ngIf="loading" fxLayoutAlign="center" style="padding-top: 150px">
  <div fxLayout="column">
    <mat-spinner></mat-spinner>
    <span fxLayoutAlign="center" style="padding-top: 20px">{{ 'LOADING' | translate }} ...</span>
  </div>
</div>

<div *ngIf="errorLoading" fxLayoutAlign="center" style="padding-top: 150px" (click)="retryLoadData()" class="link-action">
  <div fxLayout="column">
    <mat-icon fxFlexAlign="center" class="sync_problem_icon">sync_problem</mat-icon>
    <span fxFlexAlign="center" style="padding-top: 20px">{{ 'RETRY' | translate }}</span>
  </div>
</div>
