import packageInfo from '../../package.json';

export const environment = {
  production: true,
  isBeta: true,
  brand: 'bikecrm',
  brandName: 'BikeCRM',
  version: packageInfo.version + '-beta',
  apiUrl: 'https://beta.api.bikecrm.app',
  publicApiUrl: 'https://beta.connect.bikecrm.app',
  refreshRateUltraLow: 1 * 3600 * 1000,
  refreshRateLow: 5 * 60 * 1000,
  refreshRateNormal: 120 * 1000,
  refreshRateHigh: 10 * 1000,
  imageKitPubKey: 'public_yCULDEoEmivv8E8xpPj7xiAOdBE=',
  imageKitUrlEndpoint: 'https://ik.imagekit.io/kigresoft/s3mediabeta/',
  firebase: {
    apiKey: 'AIzaSyDzMYrGHZ726-gIHBIFAJTaB1W9AtOvtTQ',
    projectId: 'bikecrmbeta',
    appId: '1:1055423230141:web:0eea0430fc40f1878687d4',
    measurementId: 'G-5F7YH04XKE'
  }

};
