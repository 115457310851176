import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Observable, Subject, Subscription, timer} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogModel
} from 'src/app/components/utils/confirmation-dialog/confirmation-dialog.component';
import {
  BssBudgetStates,
  BssCheckInStates,
  BssPrioritiesStates,
  ServiceSheet,
  ServiceSheetTask
} from 'src/app/models/servicesheets';
import {User} from 'src/app/models/user';
import {Workshop, WorkshopExtraDataBssTemplate} from 'src/app/models/workshop';
import {CrmNotificationsService} from 'src/app/services/crm-notifications.service';
import {ServiceSheetService} from 'src/app/services/service-sheets.service';
import {UsersService} from 'src/app/services/users.service';
import {WorkshopService} from 'src/app/services/workshop.service';
import {environment} from 'src/environments/environment';
import {
  PredefinedTasksSelectorDialogComponent,
  PredefinedTasksSelectorDialogComponentModel
} from 'src/app/components/dialogs/predefined-tasks-selector-dialog/predefined-tasks-selector.component';
import {TitleService} from 'src/app/services/title.service';
import {NativeInterfacesService} from 'src/app/services/native-interfaces.service';
import {Hotkey, HotkeysService} from 'angular2-hotkeys';
import {BssTaskListComponent} from '../../components/bss-task-list/bss-task-list.component';
import {Fab, FabAction, FabTypes, IFab} from 'src/app/components/fab-custom/fab-interface';
import {PosOrderItemListComponent} from '../../components/pos-order-item-list/pos-order-item-list.component';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel
} from '../../components/utils/confirm-dialog/confirm-dialog.component';
import {PaymentStatusCardComponent} from '../../components/payment-status-card/payment-status-card.component';
import {ClientsDetailComponent} from '../clients-detail/clients-detail.component';
import {STEP_STATUS} from '../../components/workflow-buttons/workflow-buttons.component';
import {BillableItem, PAYMENT_METHOD_I18N_KEY} from 'src/app/models/billable_item';
import {AppMetricsService} from '../../services/app-metrics.service';

@Component({
  selector: 'app-servicesheet-detail',
  templateUrl: './servicesheet-detail.component.html',
  styleUrls: ['./servicesheet-detail.component.scss'],
  // TODO: Document:
  encapsulation: ViewEncapsulation.None
})
export class ServicesheetDetailComponent implements IFab, OnInit, OnDestroy {
  //  TODO: implement 2 way binding to keep this task list in sync with
  //        the one on the task list component, or maybe just remove task list component all together?
  //        do we gain anything having it decoupled?

  @ViewChild(BssTaskListComponent) taskList: BssTaskListComponent;
  @ViewChild(PosOrderItemListComponent) orderItemList: PosOrderItemListComponent;
  @ViewChild('paymentCard') paymentCard: PaymentStatusCardComponent;

  env = environment;

  primaryFab;
  secondaryFab;

  budgetStatusStates = BssBudgetStates;
  prioritiesStates = BssPrioritiesStates;

  mechanics$: Observable<User[]>;
  serviceSheet$: Observable<ServiceSheet>;

  owner: User;

  mechanics: User[];
  serviceSheet: ServiceSheet;
  serviceSheetId?: string;
  bikeId?: string;  // TODO: remove and use serviceSheet.bike

  defaultWorkshop$: Observable<Workshop>;

  // totalTrackedTimeLiteral = '';
  totalTrackedTimeHms = '';
  totalTrackedTime = 0;
  stopwatchStartTS = -1;

  timerObs$: Observable<number>;
  timerObsSubs: Subscription;

  finishedTasksCount: number;
  unfinishedTasksCount: number;

  totalTaskCost: number;
  totalProductsCost: number;
  bssTotalCost: number;

  currencyCode: string;

  // TODO: don't set a default
  vat = 0.21;

  steps = [
    {i18n: 'STARTED', status: STEP_STATUS.NOTSTARTED, id: 'started', type: 'button', subtitleI18n: '', disabled: false, ongoing: false},
    {i18n: 'CLOSED', status: STEP_STATUS.NOTSTARTED, id: 'closed', type: 'button', subtitleI18n: '', disabled: false, ongoing: false},
    {i18n: 'NOTIFIED', status: STEP_STATUS.NOTSTARTED, id: 'notified', type: 'button', subtitleI18n: '', disabled: false, ongoing: false},
    {i18n: 'PAID', status: STEP_STATUS.NOTSTARTED, id: 'markaspaid', type: 'button', subtitleI18n: '', disabled: false, ongoing: false},
  ];

  stepsDictionary = this.steps.reduce((acc, step, index) => {
    acc[step.id] = index;
    return acc;
  }, {});

  nextStepAction = null;

  // @ViewChild('sat-popover') dial;

  // TODO: use fully kiosk js apis, and move to a NativeInterfacesService or similar
  isFully = false;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private hotkeysService: HotkeysService,
    private titleService: TitleService,
    private translate: TranslateService,
    private notificationService: CrmNotificationsService,
    protected nativeInterfacesService: NativeInterfacesService,
    private router: Router,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private workshopService: WorkshopService,
    private serviceSheetService: ServiceSheetService,
    protected userService: UsersService,
    public appMetricsService: AppMetricsService
  ) {
    this.isFully = nativeInterfacesService.inFully;

    if (!this.isFully) {
      // Hot Keys:
      // https://github.com/brtnshrdr/angular2-hotkeys
      this.hotkeysService.add(
        new Hotkey(
          'c', // key combination
          (): boolean => { // callback function to execute after key combination
            this.addTask();
            return false; // prevent bubbling
          },
          undefined, // allow shortcut execution in these html elements
          'Create new task' // shortcut name // TODO: translate
        )
      );
    }
  }

  tasksChanged(newTasks: ServiceSheetTask[]): void {
    this.updateAggregates();
    if (!newTasks) {
      return;
    }
    // TODO: I really think that this never executes:
    for (const task of newTasks) {
      if (task.finished) {
        this.serviceSheet.started = true;
        this.updateSteps();
        break;
      }
    }
  }

  updateSteps(): void {
    let currentStepIdx = 0;  // is the last step finished


    // tslint:disable-next-line
    this.steps[this.stepsDictionary['notified']].disabled = !this.serviceSheet.closed;


    if (!this.serviceSheet.started) {
      // tslint:disable-next-line
      this.steps[this.stepsDictionary['started']].status = STEP_STATUS.NOTSTARTED;
      // tslint:disable-next-line
      currentStepIdx = this.stepsDictionary['started'];
    }
    if (this.serviceSheet.started && !this.serviceSheet.closed) {
      // tslint:disable-next-line
      this.steps[this.stepsDictionary['started']].status = STEP_STATUS.STARTED;
      // tslint:disable-next-line
      currentStepIdx = this.stepsDictionary['closed'];
    }
    if (!this.serviceSheet.closed) {
      // tslint:disable-next-line
      this.steps[this.stepsDictionary['closed']].status = STEP_STATUS.NOTSTARTED;
    }
    if (this.serviceSheet.closed) {
      // tslint:disable-next-line
      this.steps[this.stepsDictionary['started']].status = STEP_STATUS.FINISHED;
      // tslint:disable-next-line
      this.steps[this.stepsDictionary['closed']].status = STEP_STATUS.FINISHED;

      // tslint:disable-next-line
      currentStepIdx = this.stepsDictionary['notified'];
    }

    if (this.serviceSheet.notifiedClient) {
      // tslint:disable-next-line
      this.steps[this.stepsDictionary['notified']].status = STEP_STATUS.FINISHED;
      // tslint:disable-next-line
      currentStepIdx = this.stepsDictionary['markaspaid'];
    }

    // tslint:disable-next-line
    if (this.serviceSheet.roPaymentStatus === 'pai') {
      // tslint:disable-next-line
      this.steps[this.stepsDictionary['markaspaid']].type = 'button';
      // tslint:disable-next-line
      this.steps[this.stepsDictionary['markaspaid']].status = STEP_STATUS.FINISHED;
      // tslint:disable-next-line
      currentStepIdx = this.stepsDictionary['pickedup'];
      // tslint:disable-next-line
      this.steps[this.stepsDictionary['markaspaid']].subtitleI18n = PAYMENT_METHOD_I18N_KEY[this.serviceSheet.roPaymentMethod];
    } else {
      // tslint:disable-next-line
      this.steps[this.stepsDictionary['markaspaid']].status = STEP_STATUS.NOTSTARTED;
      // tslint:disable-next-line
      this.steps[this.stepsDictionary['markaspaid']].subtitleI18n = '';

      if (this.serviceSheet.roPaymentStatus === 'par') {
        // tslint:disable-next-line
        this.steps[this.stepsDictionary['markaspaid']].status = STEP_STATUS.STARTED;
        // tslint:disable-next-line
        this.steps[this.stepsDictionary['markaspaid']].subtitleI18n = PAYMENT_METHOD_I18N_KEY[this.serviceSheet.roPaymentMethod];
      }
      // tslint:disable-next-line
      this.steps[this.stepsDictionary['markaspaid']].type = 'select';
      // tslint:disable-next-line
      this.steps[this.stepsDictionary['markaspaid']]['options'] = [];

      const enabledPaymentMethods = this.userService.getCustomConfig().get_payment_methods();
      for (const paymentMethod of enabledPaymentMethods) {
        // tslint:disable-next-line
        this.steps[this.stepsDictionary['markaspaid']]['options'].push({value: paymentMethod, i18n: PAYMENT_METHOD_I18N_KEY[paymentMethod]});
      }
    }

    this.nextStepAction = {};
    // tslint:disable-next-line
    if (currentStepIdx === this.stepsDictionary['started']) {
      this.nextStepAction.i18n = this.serviceSheet.started ? 'CLOSE_SERVICE_SHEET' : 'START_SERVICE_SHEET';
      this.nextStepAction.id = 'started';
      this.nextStepAction.type = 'button';
    }

    // tslint:disable-next-line
    if (currentStepIdx === this.stepsDictionary['closed']) {
      this.nextStepAction.i18n = 'CLOSE_SERVICE_SHEET';
      this.nextStepAction.id = 'closed';
      this.nextStepAction.type = 'button';
    }

    // tslint:disable-next-line
    if (currentStepIdx === this.stepsDictionary['notified']) {
      this.nextStepAction.i18n = 'NOTIFY_CLIENT';
      this.nextStepAction.id = 'notified';
      this.nextStepAction.type = 'button';
    }

    // tslint:disable-next-line
    if (currentStepIdx === this.stepsDictionary['pickedup']) {
      this.nextStepAction.i18n = 'MARK_AS_PICK_UP';
      this.nextStepAction.id = 'pickedup';
      this.nextStepAction.type = 'button';
    }

    // tslint:disable-next-line
    if (currentStepIdx === this.stepsDictionary['markaspaid']) {
      this.nextStepAction.i18n = 'MARK_AS_PAID';
      this.nextStepAction.id = 'markaspaid';
      this.nextStepAction.type = 'select';
      this.nextStepAction.options = [];

      const enabledPaymentMethods = this.userService.getCustomConfig().get_payment_methods();
      for (const paymentMethod of enabledPaymentMethods) {
        this.nextStepAction.options.push({value: paymentMethod, i18n: PAYMENT_METHOD_I18N_KEY[paymentMethod]});
      }
    }

    // tslint:disable-next-line
    if (currentStepIdx === this.stepsDictionary['pickedup'] && this.serviceSheet.checkInStatus !== BssCheckInStates.CheckedOut) {
      this.nextStepAction.i18n = 'MARK_AS_PICK_UP';
      this.nextStepAction.id = 'pickedup';
      this.nextStepAction.type = 'button';
    }

    this.steps = this.steps.filter(obj => obj.id !== 'pickedup');
    if (this.serviceSheet.checkInStatus === BssCheckInStates.CheckedOut) {
      // disable next action button and set a final step on steps as the "picked up" completed step
      this.nextStepAction = {};
      this.steps.push({
        i18n: 'CHECKED_OUT',
        status: STEP_STATUS.FINISHED,
        id: 'pickedup',
        type: 'button',
        subtitleI18n: '',
        disabled: false,
        ongoing: false
      });
    }

  }

  onPaymentStatusChange(billableItem: BillableItem): void {
    this.serviceSheet.roPaymentStatus = billableItem.roPaymentStatus;
    this.serviceSheet.roPaymentMethod = billableItem.roPaymentMethod;
    this.serviceSheet.roPaymentDt = billableItem.roPaymentDt;
    this.updateSteps();
  }

  async nofityWhatsAppManual(): Promise<void> {
    const publicUrl = this.serviceSheetService.getTicketPublicUrl(this.serviceSheet);
    // TODO: check that i18n file is loaded before booting app, otherwise .instant will fail
    // TODO: Maybe we can return the translation from the server on bss api, depending on behicle type and client language
    const literalYouCanPickUpYourVehicle = this.translate.instant('READY_TO_PICK_UP');
    const text = `${literalYouCanPickUpYourVehicle} - Ticket: ${publicUrl}`;
    await this.openWhatsApp(text);
    await this.serviceSheetService.markAsNotifiedClientServiceSheets(this.serviceSheet.id).toPromise();
  }

  async onStepChange(value: object): Promise<void> {
    // tslint:disable-next-line:no-string-literal
    const step = value['step'];
    // tslint:disable-next-line:no-string-literal
    const stepId = step['id'];
    // tslint:disable-next-line:no-string-literal
    const menuValue = step['type'] === 'select' ? value['menuValue'] : null;
    if (stepId === 'notified' && menuValue) {
      if (menuValue === 'whatsapp-business') {
        await this.nofityWhatsAppManual();
      } else if (menuValue === 'mail') {
        // TODO: ensure that we request only mail (for future proffing other channels)
        await this.notifyClient();
      }
    }
    if (stepId === 'open') {
      if (this.taskList.isAnyTaskCompleted()) {
        // TODO
      } else {
        this.serviceSheet = await this.serviceSheetService.modify(this.serviceSheet.id, {started: false}).toPromise();
      }
    }
    if (stepId === 'started') {
      // commute between started and not started
      const startedStatus = !this.serviceSheet.started || this.taskList.isAnyTaskCompleted();
      if (startedStatus === this.serviceSheet.started && !this.serviceSheet.closed) {
        // no change, save a call
        return;
      }
      this.serviceSheet = await this.serviceSheetService.modify(this.serviceSheet.id, {
        started: startedStatus,
        closed: false
      }).toPromise();
    }
    if (stepId === 'closed') {
      if (this.taskList.areAllTasksCompleted()) {
        await this.closeServiceSheet();
      } else {
        this.dialog.open(ConfirmDialogComponent, {
          data: new ConfirmDialogModel('NOT_ALL_TASKS_COMPLETED', '', 'COMPLETE_ALL_TASKS', '', 'primary', 'CANCEL')
        }).afterClosed().subscribe(async (confirmDialog: boolean) => {
          if (confirmDialog) {
            this.taskList.closeAllTasks();
            await this.closeServiceSheet();
          } else {
            // cancelled
          }
        });
      }
    }
    if (stepId === 'notified') {
      if (this.serviceSheet.notifiedClient || step.ongoing || !this.serviceSheet.closed) {
        return;
      }
      await this.notifyClient();
    }
    if (stepId === 'pickedup') {
      await this.changeBikeCheckinStatus({value: BssCheckInStates.CheckedOut});
    }

    if (stepId === 'markaspaid') {
      // tslint:disable-next-line
      this.paymentCard.setPaid(menuValue);

      if (this.serviceSheet.checkInStatus !== BssCheckInStates.CheckedOut) {
        this.dialog.open(ConfirmDialogComponent, {
          data: new ConfirmDialogModel('MARK_AS_PICK_UP', '', 'MARK_AS_PICK_UP', '', 'primary', 'CANCEL')
        }).afterClosed().subscribe(async (confirmDialog: boolean) => {
          if (confirmDialog) {
            await this.changeBikeCheckinStatus({value: BssCheckInStates.CheckedOut});
          } else {
            // cancelled
          }
        });
        }
    }

    this.updateSteps();
  }

  // private _filter(value: string): Bike[] {
  //   const filterValue = value.toLowerCase();

  //   return this.allBikes?.filter(bike => {
  //     return bike.name.toLowerCase().indexOf(filterValue) === 0 || bike.ownerName?.toLowerCase().indexOf(filterValue) === 0;
  //   });
  // }

  ngOnInit(): void {
    console.log('DEPRECATED!!! please extend AbstractParentDetailComponent');

    this.titleService.setTitleTranslated('SERVICE_SHEET');

    this.configureDialActions();

    this.currencyCode = this.userService.business.currency.toUpperCase();
    this.vat = Number(this.userService.business.defaultVat);

    this.serviceSheetId = this.route.snapshot.paramMap.get('id');
    if (this.serviceSheetId) {
      if (this.userService.business && this.userService.business.mechanicsCount > 1) {
        this.mechanics$ = this.userService.getStaff();
        // TODO: optimize with rxjs or async await (when we can use firstValue from rxjs7 in angular 13+)?
        this.mechanics$.pipe(takeUntil(this.onDestroy$)).subscribe(x => {
          this.mechanics = x;
        });
      }

      this.serviceSheet$ = this.serviceSheetService.get(this.serviceSheetId);

      this.serviceSheet$.pipe(takeUntil(this.onDestroy$)).subscribe(x => {
        this.serviceSheet = x;
        this.updateSteps();
        this.userService.get(x.owner).pipe(takeUntil(this.onDestroy$)).subscribe(r => this.owner = r);

        this.serviceSheetService.getTimeTrackingStatus(this.serviceSheet.id).subscribe(resp => {
          this.totalTrackedTime = resp.totalSeconds;
          // this.totalTrackedTimeLiteral = this.getTotalTimeLiteral(this.totalTrackedTime);
          this.totalTrackedTimeHms = this.getTotalTimeString(this.totalTrackedTime);
          if (resp.currentlyRunning) {
            this.startTimeTracker();
          }
        });
      });

      this.defaultWorkshop$ = this.workshopService.getFirstWorkshop();
    } else {
      this.bikeId = this.route.snapshot.paramMap.get('bikeId') || this.route.snapshot.paramMap.get('parentId');
      const isBudget = (this.route.snapshot.paramMap.has('budget') && this.route.snapshot.paramMap.get('budget').toLowerCase() === 'true');
      this.createNewServiceSheet(this.bikeId, isBudget);
    }

  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  updateAggregates(): void {
    if (this.taskList.items == null) {
      this.unfinishedTasksCount = 0;
      this.finishedTasksCount = 0;
    } else {
      this.unfinishedTasksCount = this.taskList.items.filter(obj => !obj.finished).length;
      this.finishedTasksCount = this.taskList.items.filter(obj => obj.finished).length;
      this.totalTaskCost = 0;
    }

    this.totalTaskCost = this.taskList.getTotalCost();

    this.totalProductsCost = this.orderItemList.getTotalCost();

    this.bssTotalCost = this.totalTaskCost + this.totalProductsCost;
    this.serviceSheet.totalAmountWithTax = this.bssTotalCost;
    this.paymentCard.updatePaymentStatus();
  }

  // onTaskComplete(): void {
  //   this.updateAggregates();
  // }

  onProductAdded(): void {
    this.updateAggregates();
  }

  showVatFields(): void {
    this.orderItemList.showVatFields(true);
    this.taskList.showVatFields(true);
  }

  async reopenServiceSheet(): Promise<void> {
    this.serviceSheet = await this.serviceSheetService.closeServiceSheet(this.serviceSheet.id, false).toPromise();
    this.updateSteps();
  }

  async closeServiceSheet(): Promise<void> {
    this.stopTimeTracker();

    this.serviceSheet = await this.serviceSheetService.closeServiceSheet(this.serviceSheet.id, true).toPromise();
    this.updateSteps();
    // TODO: don't await first to get second translation
    const msg = await this.translate.get('NOTIFY_CLIENT_BIKE_READY_QUESTION').toPromise();
    const msgNotify = await this.translate.get('NOTIFY').toPromise();
    if (this.clientHasContactData() && this.serviceSheet.notifiedClient === false) {
      this.dialog
        .open(ConfirmationDialogComponent, {
          data: new ConfirmationDialogModel(msg, null, msgNotify, 'email')
        })
        .afterClosed()
        .subscribe(async (confirmDialog: boolean) => {
          if (confirmDialog) {
            this.notifyClient();
          } else {
            // cancelled
          }
        });
    }
  }

  sendReceiptWhatsApp(): void {
    const receiptUrl = this.serviceSheetService.getReceiptPublicUrl(this.serviceSheet);
    this.openWhatsApp(receiptUrl);
  }

  sendTicketWhatsApp(): void {
    const ticketUrl = this.serviceSheetService.getTicketPublicUrl(this.serviceSheet);
    this.openWhatsApp(ticketUrl);
  }

  sendBudgetClientWhatsApp(): void {
    const budgetUrl = this.serviceSheetService.getBudgetPublicUrl(this.serviceSheet);
    this.openWhatsApp(budgetUrl);
  }

  sendInvoiceWhatsApp(): void {
    const invoiceUrl = this.serviceSheetService.getInvoicePublicUrl(this.serviceSheet);
    this.openWhatsApp(invoiceUrl);
  }

  async sendBikeReadyWhatsApp(): Promise<void> {
    // TODO: message bike ready
    // TODO: loadin spinner or something
    // TODO: custom message templates
    // TODO: bike or kick scooter, change i18n
    const ticketUrl = this.serviceSheetService.getTicketPublicUrl(this.serviceSheet);
    const literal = await this.translate.get('BIKE_READY_TO_PICK_UP').toPromise();
    const text = `${literal} (${this.serviceSheet.bikeName}): ${ticketUrl}`;
    this.serviceSheetService.manuallyMarkBikeAsNotified(this.serviceSheet.id).subscribe(() => {
      this.openWhatsApp(text);
      this.serviceSheet.notifiedClient = true;
      this.updateSteps();
    });
  }

  async sendReceipt(): Promise<void> {
    this.serviceSheetService.sendReceiptToClient(this.serviceSheetId).subscribe(async () => {
      await this.notificationService.successI18N('RECEIPT_SENT_TO_CLIENT');
    }, async (err) => {
      await this.notificationService.errorI18N('ERROR_SENDING_RECEIPT_TO_CLIENT');
    });
  }

  async sendTicket(): Promise<void> {
    this.serviceSheetService.sendTicketToClient(this.serviceSheetId).subscribe(async () => {
      await this.notificationService.successI18N('TICKET_SENT_TO_CLIENT');
    }, async (err) => {
      await this.notificationService.errorI18N('ERROR_SENDING_TICKET_TO_CLIENT');
    });
  }

  async sendInvoice(): Promise<void> {
    const r = await this.userService.confirmInvoiceInformation(this.owner, ClientsDetailComponent).toPromise();
    if (r == null) {
      await this.notificationService.infoI18N('INVOICE_SENDING_CANCELLED_BY_USER');
      return;
    }

    this.serviceSheetService.sendInvoiceToClient(this.serviceSheetId).subscribe(async () => {
      await this.notificationService.successI18N('INVOICE_SENT_TO_CLIENT');
    }, async (err) => {
      await this.notificationService.errorI18N('ERROR_SENDING_INVOICE_TO_CLIENT');
    });
  }

  async printInvoice(): Promise<void> {
    const r = await this.userService.confirmInvoiceInformation(this.owner, ClientsDetailComponent).toPromise();
    if (r == null) {
      await this.notificationService.infoI18N('INVOICE_PRINTING_CANCELLED_BY_USER');
      return;
    }

    // tslint:disable-next-line:max-line-length
    window.open(`${environment.apiUrl}/bikes/bss/reports/${this.serviceSheetId}/invoice?tk=${this.userService.userTokenValue.token}&print=1`, '_blank');
  }

  clientHasContactData(): boolean {
    return (this.owner?.email.length > 2) || (this.owner?.phoneNumber.length > 2);
  }

  changeInvoiceSeries(selectedSeries: number): void {
    // TODO: check server response, if we have a new invoice number or if we could not change because we are not the last on the series
    if (selectedSeries === this.serviceSheet.invoiceSeries) {
      return;
    }
    this.serviceSheetService.modify(this.serviceSheetId, {invoiceSeries: selectedSeries}).subscribe((r) => {
      this.serviceSheet.invoiceNumber = r.invoiceNumber;
      this.serviceSheet.invoiceSeries = r.invoiceSeries;
      this.serviceSheet.invoiceDate = r.invoiceDate;
    }, (err) => {
      this.notificationService.warningI18N(err.error[0]);

      // this is just to trigger the change detection and make select go back to the previous value:
      const oldSeries = this.serviceSheet.invoiceSeries;
      this.serviceSheet.invoiceSeries = 99999;
      setTimeout(() => {
        this.serviceSheet.invoiceSeries = oldSeries;
      });

    });
  }

  generateInvoice(): void {
    this.serviceSheetService.generateInvoice(this.serviceSheetId).subscribe((r) => {
      this.serviceSheet.invoiceNumber = r.invoiceNumber;
      this.serviceSheet.invoiceSeries = r.invoiceSeries;
      this.serviceSheet.invoiceDate = r.invoiceDate;
    });
  }

  async sendBudgetClient(): Promise<void> {
    this.updateAggregates();
    const msgError = this.translate.get('FEEDBACK_MESSAGES.CHECK_INTERNET_CONNECTION').toPromise();
    const titleError = this.translate.get('FEEDBACK_MESSAGES.ERROR_SENDING_BUDGET').toPromise();
    this.serviceSheetService.sendBudgetClientServiceSheets(this.serviceSheet.id).pipe(takeUntil(this.onDestroy$)).subscribe(
      r => {
      },
      async err => this.notificationService.error(await msgError, await titleError),
      async () => {
        this.notificationService.info(await this.translate.get('BUDGET_SENT_TO_CLIENT').toPromise());
        this.serviceSheet.notifiedClient = true;
      },
    );
  }

  async notifyClient(): Promise<void> {
    // tslint:disable-next-line
    this.steps[this.stepsDictionary['notified']].ongoing = true;

    if (this.userService.business.userMobileNotificationConfig === 'whatsapp-manual') {
      this.nofityWhatsAppManual();
    }

    const msgError = this.translate.get('FEEDBACK_MESSAGES.CHECK_INTERNET_CONNECTION').toPromise();
    const titleError = this.translate.get('FEEDBACK_MESSAGES.ERROR_NOTIFYING_CLIENT').toPromise();
    this.serviceSheetService.notifyClientServiceSheets(this.serviceSheet.id).pipe(takeUntil(this.onDestroy$)).subscribe(
      r => {
        // tslint:disable-next-line
        this.steps[this.stepsDictionary['notified']].ongoing = false;
      },
      async err => this.notificationService.error(await msgError, await titleError),
      async () => {
        this.notificationService.info(await this.translate.get('CLIENT_NOTIFIED').toPromise());
        this.serviceSheet.notifiedClient = true;
        this.updateSteps();
      },
    );
  }

  addTask(task?: ServiceSheetTask): void {
    this.taskList.addItem(task);
  }

  async configureDialActions(): Promise<void> {
    this.primaryFab = new Fab(
      'TASK_DASH_PRODUCT',
      'add',
      'fab_add_product',
      FabTypes.multipleAction,
      [
        // new FabAction('PACKS', '', 'action_add_pack'),
        new FabAction('SEARCH_PRODUCT', '', 'action_search_product'),
        new FabAction('PREDEFINED_TASKS', '', 'action_add_predefined_task'),
        new FabAction('NEW_TASK', '', 'action_add_new_task')
      ]);
    if (this.isFully || this.nativeInterfacesService.hasCamera) {
      this.secondaryFab = new Fab('', 'qr_code_scanner', 'fab_add_by_cam_code_scanner', FabTypes.singleAction);
    }
  }

  onFabAction(actionId: string): boolean {
    if (actionId === 'action_add_new_task') {
      this.addTask();
      return true;
    } else if (actionId === 'action_add_predefined_task') {
      this.dialog
        .open(PredefinedTasksSelectorDialogComponent, {
          maxHeight: '90vh',
          width: '75%',
          // TODO: we need 2-way data binding for this to be reliable:
          data: new PredefinedTasksSelectorDialogComponentModel(this.taskList.items.map(obj => obj.taskDescription))
        })
        .afterClosed()
        .subscribe(async (selectedTemplates: WorkshopExtraDataBssTemplate[]) => {
          selectedTemplates?.forEach((element) => {
            const task = new ServiceSheetTask();
            task.taskDescription = element.name;
            task.costOther = WorkshopExtraDataBssTemplate.priceMaterialsWoTax(element, this.vat);
            task.costHours = WorkshopExtraDataBssTemplate.priceLaborWoTax(element, this.vat);
            this.addTask(task);
          });
        });
      return true;
    } else if (actionId === 'fab_add_by_cam_code_scanner') {
      this.orderItemList.startCamBarcodeScanner();
      return true;
    } else if (actionId === 'action_search_product') {
      this.orderItemList.searchExistingProductDialog();
      return true;
    }
    console.log(actionId + ' not implemented');
    return true;
  }

  downloadPDF(pdfType = null): void {
    const format = 'a4';
    if (pdfType === null) {
      pdfType = this.serviceSheet.closed ? 'ticket' : 'budget';
    }
    window.open(`${environment.apiUrl}/bikes/bss/reports/${this.serviceSheetId}/${pdfType}/${format}?tk=${this.userService.userTokenValue.token}&pdf=1`, '_blank');
  }

  deleteBSS(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: new ConfirmDialogModel('CONFIRM_DELETE_BSS', '', 'DELETE', 'delete', 'warn')
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(async (confirmDialog: boolean) => {
        if (confirmDialog) {
          // raise exception on ng on init if we ever try to build a deleted component
          this.serviceSheetService.delete(this.serviceSheetId).subscribe( () => {
            this.router.navigateByUrl('/servicesheets', {replaceUrl: true});
          }, (err) => {
            // tslint:disable-next-line:no-string-literal
            this.notificationService.errorI18N(err.error['detail']);
          });
        } else {
          // cancelled
        }
      });
  }

  printReceipt(): void {
    // tslint:disable-next-line:max-line-length
    window.open(`${environment.apiUrl}/bikes/bss/reports/${this.serviceSheetId}/receipt?tk=${this.userService.userTokenValue.token}&print=1`, '_blank');
  }

  print(sizeType: string): void {
    let pdfType = 'budget';
    if (this.serviceSheet.closed) {
      pdfType = 'ticket';
    }
    window.open(`${environment.apiUrl}/bikes/bss/reports/${this.serviceSheetId}/${pdfType}/${sizeType}?tk=${this.userService.userTokenValue.token}&print=1`, '_blank');
  }

  trackClassicButtonsUsed(): void {
    this.appMetricsService.sendEvent(this.appMetricsService.EVENT_NAMES.BSS_CONTROLS, 'classic-buttons');
  }

  trackClassicSendPrintButtonsUsed(): void {
    this.appMetricsService.sendEvent(this.appMetricsService.EVENT_NAMES.BSS_CONTROLS, 'classic-buttons-print-send');
  }

  trackNewSendPrintButtonsUsed(): void {
    this.appMetricsService.sendEvent(this.appMetricsService.EVENT_NAMES.BSS_CONTROLS, 'new-buttons-print-send');
  }

  async checkOutBike(): Promise<void> {
    await this.serviceSheetService.modify(this.serviceSheet.id,
      {checkInStatus: BssCheckInStates.CheckedOut}).toPromise().then(
      async res => { // Success
        this.serviceSheet.checkInStatus = BssCheckInStates.CheckedOut;
        this.notificationService.success(
          await this.translate.get('BIKE_CHECKED_OUT').toPromise()
        );
      },
      async msg => { // Error
        this.notificationService.error(
          await this.translate.get('FEEDBACK_MESSAGES.CHECK_INTERNET_CONNECTION').toPromise(),
          await this.translate.get('FEEDBACK_MESSAGES.ERROR_MARKING_BIKE_AS_CHECKED_OUT').toPromise()
        );
      }
    );
  }

  async assignTo(evt): Promise<void> {
    // TODO: unify code with deliveryDateChange
    // console.log(evt.value);
    // console.log(this.serviceSheet.assignedTo);
    // assert evt.value == this.serviceSheet.assignedTo; on tests

    if (evt == null) {
      return;
    }
    const mechId = evt.value;
    // TODO: translate
    await this.serviceSheetService.modify(this.serviceSheet.id,
      {assignedTo: mechId}).toPromise().then(
      async res => { // Success
        this.serviceSheet.assignedTo = mechId;
        this.notificationService.success(
          await this.translate.get('ASSIGNED_TO_MECHANIC').toPromise()
        );
      },
      async msg => { // Error
        this.notificationService.error(
          await this.translate.get('FEEDBACK_MESSAGES.CHECK_INTERNET_CONNECTION').toPromise(),
          await this.translate.get('FEEDBACK_MESSAGES.ERROR_ASSIGNING_TO_MECHANIC').toPromise()
        );
      }
    );
  }

  async changePriority(evt): Promise<void> {
    // TODO: unify code with deliveryDateChange and changeBudgetStatus
    // console.log(evt.value);
    // assert evt.value == this.serviceSheet.priority; on tests

    if (evt == null) {
      return;
    }
    const newPriority = evt.value;
    // TODO: translate
    await this.serviceSheetService.modify(this.serviceSheet.id,
      {priority: newPriority}).toPromise().then(
      async res => { // Success
        this.serviceSheet.priority = newPriority;
        this.notificationService.success(
          await this.translate.get('CHANGED_PRIORITY_STATUS').toPromise()
        );
      },
      async msg => { // Error
        this.notificationService.error(
          await this.translate.get('FEEDBACK_MESSAGES.CHECK_INTERNET_CONNECTION').toPromise(),
          await this.translate.get('FEEDBACK_MESSAGES.ERROR_ASSIGNING_TO_MECHANIC').toPromise()
        );
      }
    );
  }

  async changeBikeCheckinStatus(evt): Promise<void> {
    if (evt == null) {
      // Can this happen? maybe if the user clicks on the same value?
      return;
    }
    await this.serviceSheetService.modify(
      this.serviceSheet.id,
      {checkInStatus: evt.value}
    ).toPromise().then(
      async res => { // Success
        this.serviceSheet.checkInStatus = evt.value;
        this.notificationService.success(
          await this.translate.get('CHANGED_BIKE_CHECKIN_STATUS').toPromise()
        );
        this.updateSteps();
      },
      async msg => { // Error
        this.notificationService.error(
          await this.translate.get('FEEDBACK_MESSAGES.CHECK_INTERNET_CONNECTION').toPromise(),
          await this.translate.get('FEEDBACK_MESSAGES.ERROR_CHANGING_BIKE_CHECKIN_STATUS').toPromise()
        );
        this.updateSteps();
      }
    );
  }

  async changeBudgetStatus(evt): Promise<void> {
    // TODO: unify code with deliveryDateChange and changePriority
    // console.log(evt.value);
    // console.log(this.serviceSheet.budgetStatus);
    // assert evt.value == this.serviceSheet.budgetStatus; on tests

    if (evt == null || evt === undefined) {
      return;
    }
    const newBudgetStatus = evt.value;
    // TODO: translate
    await this.serviceSheetService.modify(this.serviceSheet.id,
      {budgetStatus: newBudgetStatus}).toPromise().then(
      async res => { // Success
        this.serviceSheet.budgetStatus = newBudgetStatus;
        this.notificationService.success(
          await this.translate.get('CHANGED_BUDGET_STATUS').toPromise()
        );
      },
      async msg => { // Error
        this.notificationService.error(
          await this.translate.get('FEEDBACK_MESSAGES.CHECK_INTERNET_CONNECTION').toPromise(),
          await this.translate.get('FEEDBACK_MESSAGES.ERROR_ASSIGNING_TO_MECHANIC').toPromise()
        );
      }
    );
  }

  isPastDate(date: string): boolean {
    const d = new Date(date);
    return d < new Date();
  }

  async estimatedCheckinDateChange(evt): Promise<void> {
    // TODO: unify code with deliveryDateChange
    if (evt == null) {
      return;
    }

    let d = evt;
    if (d.length === 0) {
      d = null;
    }
    await this.serviceSheetService.modify(this.serviceSheet.id,
      {checkInScheduledDt: d}).toPromise().then(
      async res => { // Success
        this.serviceSheet.checkInScheduledDt = d;
        this.notificationService.success(
          await this.translate.get('ESTIMATED_CHECKIN_DATE_UPDATED').toPromise()
        );
      },
      async msg => { // Error
        this.notificationService.error(
          await this.translate.get('FEEDBACK_MESSAGES.CHECK_INTERNET_CONNECTION').toPromise(),
          await this.translate.get('FEEDBACK_MESSAGES.ERROR_UPDATING_ESTIMATED_CHECKIN_DATE').toPromise()
        );
      }
    );
  }

  async deliveryDateChange(evt): Promise<void> {
    if (evt == null) {
      return;
    }
    let d = evt;
    if (d.length === 0) {
      d = null;
    }
    d = new Date(d);
    d.setHours(d.getHours() + 9);
    await this.serviceSheetService.modify(this.serviceSheet.id,
      {estimatedDeliveryDt: d}).toPromise().then(
      async res => { // Success
        this.serviceSheet.estimatedDeliveryDt = d;
        this.notificationService.success(
          await this.translate.get('DELIVERY_DATA_UPDATED').toPromise()
        );
      },
      async msg => { // Error
        this.notificationService.error(
          await this.translate.get('FEEDBACK_MESSAGES.CHECK_INTERNET_CONNECTION').toPromise(),
          await this.translate.get('FEEDBACK_MESSAGES.ERROR_UPDATING_DELIVERY_DATE').toPromise()
        );
      }
    );
  }

  getCheckInStatusSelectTriggerIconAndText(): {icon: string, texti18n: string, class: string} {
    if (this.serviceSheet.checkInStatus === 'ret') {
      return {icon: 'airport_shuttle', texti18n: 'PENDING_SCHEDULED_PICK_UP', class: 'text-warning'};
    }
    if (this.serviceSheet.checkInStatus === 'cpe') {
      return {icon: 'event_upcoming', texti18n: 'PENDING_CHECK_IN', class: 'text-warning'};
    }
    if (this.serviceSheet.checkInStatus === 'cin') {
      return {icon: 'build', texti18n: 'CHECKED_IN', class: ''};
    }
    if (this.serviceSheet.checkInStatus === 'cou') {
      return {icon: 'check_circle', texti18n: 'CHECKED_OUT', class: 'text-success'};
    }
  }

  createNewServiceSheet(bikeID: string, isBudget: boolean): void {
    const formData = new FormData();
    // TODO: i18n, something generic like service sheet from xx date....
    formData.append('bike', bikeID);
    if (isBudget) {
      formData.append('budgetStatus', 'nea');
    }

    this.serviceSheetService.create(formData).pipe(takeUntil(this.onDestroy$)).subscribe(
      x => this.router.navigate([`/servicesheets/${x.id}`], {replaceUrl: true})
    );
  }

  getTotalTimeString(time: number): string {
    return new Date(time * 1000).toISOString().substr(11, 8);
  }

  startTimeTracker(): void {
    this.serviceSheetService.startTimeTracking(this.serviceSheet.id).subscribe(resp => {
      // console.log('startTimeTracking');
      // console.log(resp);
      this.stopwatchStartTS = Date.now() / 1000;
      this.timerObs$ = timer(0, 1000);
      this.totalTrackedTime = resp.totalSeconds;
      // this.totalTrackedTimeLiteral = this.getTotalTimeLiteral(this.totalTrackedTime);
      this.totalTrackedTimeHms = this.getTotalTimeString(this.totalTrackedTime);
      this.timerObsSubs = this.timerObs$.pipe(takeUntil(this.onDestroy$)).subscribe(x => {
        // We use current clock timestamp to avoid problems with power saving on tablets, phones, etc.
        //    that cause the timer to go off
        this.totalTrackedTime = resp.totalSeconds + (Date.now() / 1000 - this.stopwatchStartTS);
        // this.totalTrackedTimeLiteral = this.getTotalTimeLiteral(this.totalTrackedTime);
        this.totalTrackedTimeHms = this.getTotalTimeString(this.totalTrackedTime);
      });
    });
  }

  whatsAppProbablyInstalled(): boolean {
    // TODO: move to a service? and dedup with abstract item detail and service sheet detail
    // TODO: improve somehow
    return /iPhone|Android/i.test(navigator.userAgent);
  }

  openCall(): void {
    // TODO: move to a service? and dedup with abstract item detail and service sheet detail
    window.open(`tel:${this.serviceSheet.ownerPhoneFormatted}`, '_blank');
  }

  async openWhatsApp(text: string = null): Promise<void> {
    // TODO: move to a service? and dedup with abstract item detail and service sheet detail
    // TODO: we can also send a link to the bss to the client,
    //      but right now the server won't return a visible page if the bss is not finished
    let p = this.serviceSheet.ownerPhoneFormatted;

    // https://faq.whatsapp.com/general/chats/how-to-use-click-to-chat/?lang=en
    // https://stackoverflow.com/questions/21935149/sharing-link-on-whatsapp-from-mobile-website-not-application-for-android
    p = p.split('-').join('');
    p = p.split('+').join('');
    p = p.split('(').join('');
    p = p.split(')').join('');
    p = p.replace(/^0+/, '');

    // TODO: improve this logic, what happens with other countries? and if the phone starts with 34 but it still has no country code on it
    // TODO: this should come from the server, with a default prefix for the workshop in all the phones
    // TODO: remove
    // if (p.slice(0, 2) !== '34') {
    //   p = `34${p}`;
    // }

    // const url = `https://wa.me/${p}`;
    if (!text) {
      const literal = await this.translate.get('CTX_SERVICE_SHEETS.ABOUT_SERVICE_SHEET').toPromise();
      text = `${literal}: ${this.serviceSheet.shortPrettyId}`;
    }
    const url = `https://api.whatsapp.com/send?text=${text}&phone=${p}`;
    // if (this.whatsAppProbablyInstalled()) {
    //   url = `https://web.whatsapp.com/send?text=${text}&phone=${p}`;
    // }
    // window.open(url, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    // console.log(url);
    window.open(url, '_blank');
  }

  stopTimeTracker(): void {
    if (!this.timerObsSubs) {
      return;
    }

    this.timerObsSubs.unsubscribe();
    this.timerObs$ = null;
    this.serviceSheetService.stopTimeTracking(this.serviceSheet.id).subscribe(resp => {
      this.totalTrackedTime = resp.totalSeconds;
      // this.totalTrackedTimeLiteral = this.getTotalTimeLiteral(this.totalTrackedTime);
      this.totalTrackedTimeHms = this.getTotalTimeString(this.totalTrackedTime);
      this.stopwatchStartTS = -1;
    });
  }

}
